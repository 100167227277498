import logo from '../assets/images/logo-white-full.svg';

export const LoadingSplash = () => {
    return (
        <div className="h-custom-screen flex w-screen items-center justify-center">
            <img
                src={logo}
                alt="bookbio-logo"
                className="rounded-smd h-40 w-40"
            />
        </div>
    );
};
