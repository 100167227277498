import { Business, Service } from '@bookinbio/interface';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getServicesByBusiness = async (
    business?: Business,
): Promise<(Service & { professionalIds: string[] })[]> => {
    if (!business) {
        throw new Error('No business was provided');
    }

    const q = query(
        collection(doc(businessesColl, business.id), 'services'),
        where('serviceSettings.allowBookings', '==', true),
    );
    const servicesSnapshot = await getDocs(q);

    const servicesData: (Service & { professionalIds: string[] })[] = [];
    servicesSnapshot.forEach((doc) => {
        const data = { ...doc.data() } as Service;
        servicesData.push({
            id: doc.id,
            ...doc.data(),
            basicInfo: {
                ...data.basicInfo,
                order: data.basicInfo.order ?? 999,
                isFavorite: data.basicInfo.isFavorite ?? false,
            },
            professionalIds: data.professionalRefs.map((profRef) => profRef.id),
        } as Service & { professionalIds: string[] });
    });
    return servicesData;
};
