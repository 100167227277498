import {
    AppointmentHandleOption,
    RecurringUpdateOption,
} from '@bookinbio/enums';
import { GoogleEvent } from '@bookinbio/interface';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

interface BookAppointment {
    businessId: string;
    serviceIds: string[];
    locationId: string;
    professionalId: string | null;
    rentIds: string[] | null;
    serviceType: string;

    fullName: string;
    phoneNumber: string;
    email: string;

    start: string;

    notes?: string;
}

export const bookAppointment = httpsCallable<BookAppointment, string>(
    functions,
    'bookAppointment',
);

type CancelAppointmentArgs = {
    businessId: string;
    appointmentId: string;
    cancelOption: RecurringUpdateOption;
    purpose: AppointmentHandleOption;
};

export const cancelAppointment = httpsCallable<CancelAppointmentArgs>(
    functions,
    'cancelAppointment',
);

interface GetDayGoogleEvent {
    userId?: string;
    date: string;
    timezone: string;
}

export const getDayGoogleEvents = httpsCallable<
    GetDayGoogleEvent,
    GoogleEvent[]
>(functions, 'getDayGoogleEvents');
