export enum TimeSlotCreationStrategy {
    RegularStrategy = 'REGULAR_STRATEGY',
    ReduceCalendarGaps = 'REDUCE_CALENDAR_GAPS',
    EliminateCalendarGaps = 'ELIMINATE_CALENDAR_GAPS',
}

export enum EliminateGapsStrategy {
    StartEndOfDay = 'START_END_OF_DAY',
    StartOfDay = 'START_OF_DAY',
    EndOfDay = 'END_OF_DAY',
}
