import { Business, Customer, Product, User } from '@bookinbio/interface';
import {
    collection,
    CollectionReference,
    DocumentData,
} from 'firebase/firestore';

import { firestore } from '../firebase.config';

export const collectionConverter = <T = DocumentData>(
    collectionName: string,
) => {
    return collection(firestore, collectionName) as CollectionReference<T>;
};

export const usersColl = collectionConverter<Omit<User, 'id'>>('users');

export const businessesColl =
    collectionConverter<Omit<Business, 'id'>>('businesses');

// Stripe collections
export const productsColl =
    collectionConverter<Omit<Product, 'id'>>('products');

export const customersColl =
    collectionConverter<Omit<Customer, 'id'>>('customers');
