import { Business } from '@bookinbio/interface';
import { getDocs, query, where } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getBusinessBySocialName = async (
    socialName?: string,
): Promise<Business> => {
    if (!socialName) {
        throw new Error(`Such business doesn't exist`);
    }

    const businessQuery = query(
        businessesColl,
        where('socialName', '==', socialName),
    );

    const businessSnapshot = await getDocs(businessQuery);

    if (businessSnapshot.empty) {
        throw new Error(`Such business doesn't exist`);
    }

    const businessDoc = businessSnapshot.docs[0];
    const businessData = businessDoc.data();

    return {
        ...businessData,
        id: businessDoc.id,
    };
};
