import { TimeFormat } from '@bookinbio/api';

export const TIME_DURATION_OPTIONS = [
    { value: 5, label: '5 min' },
    { value: 10, label: '10 min' },
    { value: 15, label: '15 min' },
    { value: 20, label: '20 min' },
    { value: 25, label: '25 min' },
    { value: 30, label: '30 min' },
    { value: 35, label: '35 min' },
    { value: 40, label: '40 min' },
    { value: 45, label: '45 min' },
    { value: 50, label: '50 min' },
    { value: 55, label: '55 min' },
    { value: 60, label: '1 hour' },
    { value: 65, label: '1 hour 5 min' },
    { value: 70, label: '1 hour 10 min' },
    { value: 75, label: '1 hour 15 min' },
    { value: 80, label: '1 hour 20 min' },
    { value: 85, label: '1 hour 25 min' },
    { value: 90, label: '1 hour 30 min' },
    { value: 95, label: '1 hour 35 min' },
    { value: 95, label: '1 hour 35 min' },
    { value: 100, label: '1 hour 40 min' },
    { value: 105, label: '1 hour 45 min' },
    { value: 110, label: '1 hour 50 min' },
    { value: 115, label: '1 hour 55 min' },
    { value: 120, label: '2 hours' },
    { value: 135, label: '2 hours 15 min' },
    { value: 150, label: '2 hours 30 min' },
    { value: 165, label: '2 hours 45 min' },
    { value: 180, label: '3 hours' },
    { value: 195, label: '3 hours 15 min' },
    { value: 210, label: '3 hours 30 min' },
    { value: 225, label: '3 hours 45 min' },
    { value: 240, label: '4 hours' },
    { value: 270, label: '4 hours 30 min' },
    { value: 300, label: '5 hours' },
    { value: 330, label: '5 hours 30 min' },
    { value: 360, label: '6 hours' },
    { value: 390, label: '6 hours 30 min' },
    { value: 420, label: '7 hours' },
    { value: 450, label: '7 hours 30 min' },
    { value: 480, label: '8 hours' },
    { value: 540, label: '9 hours' },
    { value: 600, label: '10 hours' },
    { value: 660, label: '11 hours' },
    { value: 720, label: '12 hours' },
];

export const REST_TIME_OPTIONS = [
    { value: 0, label: 'None' },
    { value: 5, label: '5 min' },
    { value: 10, label: '10 min' },
    { value: 15, label: '15 min' },
    { value: 20, label: '20 min' },
    { value: 25, label: '25 min' },
    { value: 30, label: '30 min' },
    { value: 35, label: '35 min' },
    { value: 40, label: '40 min' },
    { value: 45, label: '45 min' },
    { value: 50, label: '50 min' },
    { value: 55, label: '55 min' },
    { value: 60, label: '1 hour' },
];

export const TIME_OPTIONS = [
    { value: '00:00', label: '00:00' },
    { value: '00:05', label: '00:05' },
    { value: '00:10', label: '00:10' },
    { value: '00:15', label: '00:15' },
    { value: '00:20', label: '00:20' },
    { value: '00:25', label: '00:25' },
    { value: '00:30', label: '00:30' },
    { value: '00:35', label: '00:35' },
    { value: '00:40', label: '00:40' },
    { value: '00:45', label: '00:45' },
    { value: '00:50', label: '00:50' },
    { value: '00:55', label: '00:55' },
    { value: '01:00', label: '01:00' },
    { value: '01:05', label: '01:05' },
    { value: '01:10', label: '01:10' },
    { value: '01:15', label: '01:15' },
    { value: '01:20', label: '01:20' },
    { value: '01:25', label: '01:25' },
    { value: '01:30', label: '01:30' },
    { value: '01:35', label: '01:35' },
    { value: '01:40', label: '01:40' },
    { value: '01:45', label: '01:45' },
    { value: '01:50', label: '01:50' },
    { value: '01:55', label: '01:55' },
    { value: '02:00', label: '02:00' },
    { value: '02:05', label: '02:05' },
    { value: '02:10', label: '02:10' },
    { value: '02:15', label: '02:15' },
    { value: '02:20', label: '02:20' },
    { value: '02:25', label: '02:25' },
    { value: '02:30', label: '02:30' },
    { value: '02:35', label: '02:35' },
    { value: '02:40', label: '02:40' },
    { value: '02:45', label: '02:45' },
    { value: '02:50', label: '02:50' },
    { value: '02:55', label: '02:55' },
    { value: '03:00', label: '03:00' },
    { value: '03:05', label: '03:05' },
    { value: '03:10', label: '03:10' },
    { value: '03:15', label: '03:15' },
    { value: '03:20', label: '03:20' },
    { value: '03:25', label: '03:25' },
    { value: '03:30', label: '03:30' },
    { value: '03:35', label: '03:35' },
    { value: '03:40', label: '03:40' },
    { value: '03:45', label: '03:45' },
    { value: '03:50', label: '03:50' },
    { value: '03:55', label: '03:55' },
    { value: '04:00', label: '04:00' },
    { value: '04:05', label: '04:05' },
    { value: '04:10', label: '04:10' },
    { value: '04:15', label: '04:15' },
    { value: '04:20', label: '04:20' },
    { value: '04:25', label: '04:25' },
    { value: '04:30', label: '04:30' },
    { value: '04:35', label: '04:35' },
    { value: '04:40', label: '04:40' },
    { value: '04:45', label: '04:45' },
    { value: '04:50', label: '04:50' },
    { value: '04:55', label: '04:55' },
    { value: '05:00', label: '05:00' },
    { value: '05:05', label: '05:05' },
    { value: '05:10', label: '05:10' },
    { value: '05:15', label: '05:15' },
    { value: '05:20', label: '05:20' },
    { value: '05:25', label: '05:25' },
    { value: '05:30', label: '05:30' },
    { value: '05:35', label: '05:35' },
    { value: '05:40', label: '05:40' },
    { value: '05:45', label: '05:45' },
    { value: '05:50', label: '05:50' },
    { value: '05:55', label: '05:55' },
    { value: '06:00', label: '06:00' },
    { value: '06:05', label: '06:05' },
    { value: '06:10', label: '06:10' },
    { value: '06:15', label: '06:15' },
    { value: '06:20', label: '06:20' },
    { value: '06:25', label: '06:25' },
    { value: '06:30', label: '06:30' },
    { value: '06:35', label: '06:35' },
    { value: '06:40', label: '06:40' },
    { value: '06:45', label: '06:45' },
    { value: '06:50', label: '06:50' },
    { value: '06:55', label: '06:55' },
    { value: '07:00', label: '07:00' },
    { value: '07:05', label: '07:05' },
    { value: '07:10', label: '07:10' },
    { value: '07:15', label: '07:15' },
    { value: '07:20', label: '07:20' },
    { value: '07:25', label: '07:25' },
    { value: '07:30', label: '07:30' },
    { value: '07:35', label: '07:35' },
    { value: '07:40', label: '07:40' },
    { value: '07:45', label: '07:45' },
    { value: '07:50', label: '07:50' },
    { value: '07:55', label: '07:55' },
    { value: '08:00', label: '08:00' },
    { value: '08:05', label: '08:05' },
    { value: '08:10', label: '08:10' },
    { value: '08:15', label: '08:15' },
    { value: '08:20', label: '08:20' },
    { value: '08:25', label: '08:25' },
    { value: '08:30', label: '08:30' },
    { value: '08:35', label: '08:35' },
    { value: '08:40', label: '08:40' },
    { value: '08:45', label: '08:45' },
    { value: '08:50', label: '08:50' },
    { value: '08:55', label: '08:55' },
    { value: '09:00', label: '09:00' },
    { value: '09:05', label: '09:05' },
    { value: '09:10', label: '09:10' },
    { value: '09:15', label: '09:15' },
    { value: '09:20', label: '09:20' },
    { value: '09:25', label: '09:25' },
    { value: '09:30', label: '09:30' },
    { value: '09:35', label: '09:35' },
    { value: '09:40', label: '09:40' },
    { value: '09:45', label: '09:45' },
    { value: '09:50', label: '09:50' },
    { value: '09:55', label: '09:55' },
    { value: '10:00', label: '10:00' },
    { value: '10:05', label: '10:05' },
    { value: '10:10', label: '10:10' },
    { value: '10:15', label: '10:15' },
    { value: '10:20', label: '10:20' },
    { value: '10:25', label: '10:25' },
    { value: '10:30', label: '10:30' },
    { value: '10:35', label: '10:35' },
    { value: '10:40', label: '10:40' },
    { value: '10:45', label: '10:45' },
    { value: '10:50', label: '10:50' },
    { value: '10:55', label: '10:55' },
    { value: '11:00', label: '11:00' },
    { value: '11:05', label: '11:05' },
    { value: '11:10', label: '11:10' },
    { value: '11:15', label: '11:15' },
    { value: '11:20', label: '11:20' },
    { value: '11:25', label: '11:25' },
    { value: '11:30', label: '11:30' },
    { value: '11:35', label: '11:35' },
    { value: '11:40', label: '11:40' },
    { value: '11:45', label: '11:45' },
    { value: '11:50', label: '11:50' },
    { value: '11:55', label: '11:55' },
    { value: '12:00', label: '12:00' },
    { value: '12:05', label: '12:05' },
    { value: '12:10', label: '12:10' },
    { value: '12:15', label: '12:15' },
    { value: '12:20', label: '12:20' },
    { value: '12:25', label: '12:25' },
    { value: '12:30', label: '12:30' },
    { value: '12:35', label: '12:35' },
    { value: '12:40', label: '12:40' },
    { value: '12:45', label: '12:45' },
    { value: '12:50', label: '12:50' },
    { value: '12:55', label: '12:55' },
    { value: '13:00', label: '13:00' },
    { value: '13:05', label: '13:05' },
    { value: '13:10', label: '13:10' },
    { value: '13:15', label: '13:15' },
    { value: '13:20', label: '13:20' },
    { value: '13:25', label: '13:25' },
    { value: '13:30', label: '13:30' },
    { value: '13:35', label: '13:35' },
    { value: '13:40', label: '13:40' },
    { value: '13:45', label: '13:45' },
    { value: '13:50', label: '13:50' },
    { value: '13:55', label: '13:55' },
    { value: '14:00', label: '14:00' },
    { value: '14:05', label: '14:05' },
    { value: '14:10', label: '14:10' },
    { value: '14:15', label: '14:15' },
    { value: '14:20', label: '14:20' },
    { value: '14:25', label: '14:25' },
    { value: '14:30', label: '14:30' },
    { value: '14:35', label: '14:35' },
    { value: '14:40', label: '14:40' },
    { value: '14:45', label: '14:45' },
    { value: '14:50', label: '14:50' },
    { value: '14:55', label: '14:55' },
    { value: '15:00', label: '15:00' },
    { value: '15:05', label: '15:05' },
    { value: '15:10', label: '15:10' },
    { value: '15:15', label: '15:15' },
    { value: '15:20', label: '15:20' },
    { value: '15:25', label: '15:25' },
    { value: '15:30', label: '15:30' },
    { value: '15:35', label: '15:35' },
    { value: '15:40', label: '15:40' },
    { value: '15:45', label: '15:45' },
    { value: '15:50', label: '15:50' },
    { value: '15:55', label: '15:55' },
    { value: '16:00', label: '16:00' },
    { value: '16:05', label: '16:05' },
    { value: '16:10', label: '16:10' },
    { value: '16:15', label: '16:15' },
    { value: '16:20', label: '16:20' },
    { value: '16:25', label: '16:25' },
    { value: '16:30', label: '16:30' },
    { value: '16:35', label: '16:35' },
    { value: '16:40', label: '16:40' },
    { value: '16:45', label: '16:45' },
    { value: '16:50', label: '16:50' },
    { value: '16:55', label: '16:55' },
    { value: '17:00', label: '17:00' },
    { value: '17:05', label: '17:05' },
    { value: '17:10', label: '17:10' },
    { value: '17:15', label: '17:15' },
    { value: '17:20', label: '17:20' },
    { value: '17:25', label: '17:25' },
    { value: '17:30', label: '17:30' },
    { value: '17:35', label: '17:35' },
    { value: '17:40', label: '17:40' },
    { value: '17:45', label: '17:45' },
    { value: '17:50', label: '17:50' },
    { value: '17:55', label: '17:55' },
    { value: '18:00', label: '18:00' },
    { value: '18:05', label: '18:05' },
    { value: '18:10', label: '18:10' },
    { value: '18:15', label: '18:15' },
    { value: '18:20', label: '18:20' },
    { value: '18:25', label: '18:25' },
    { value: '18:30', label: '18:30' },
    { value: '18:35', label: '18:35' },
    { value: '18:40', label: '18:40' },
    { value: '18:45', label: '18:45' },
    { value: '18:50', label: '18:50' },
    { value: '18:55', label: '18:55' },
    { value: '19:00', label: '19:00' },
    { value: '19:05', label: '19:05' },
    { value: '19:10', label: '19:10' },
    { value: '19:15', label: '19:15' },
    { value: '19:20', label: '19:20' },
    { value: '19:25', label: '19:25' },
    { value: '19:30', label: '19:30' },
    { value: '19:35', label: '19:35' },
    { value: '19:40', label: '19:40' },
    { value: '19:45', label: '19:45' },
    { value: '19:50', label: '19:50' },
    { value: '19:55', label: '19:55' },
    { value: '20:00', label: '20:00' },
    { value: '20:05', label: '20:05' },
    { value: '20:10', label: '20:10' },
    { value: '20:15', label: '20:15' },
    { value: '20:20', label: '20:20' },
    { value: '20:25', label: '20:25' },
    { value: '20:30', label: '20:30' },
    { value: '20:35', label: '20:35' },
    { value: '20:40', label: '20:40' },
    { value: '20:45', label: '20:45' },
    { value: '20:50', label: '20:50' },
    { value: '20:55', label: '20:55' },
    { value: '21:00', label: '21:00' },
    { value: '21:05', label: '21:05' },
    { value: '21:10', label: '21:10' },
    { value: '21:15', label: '21:15' },
    { value: '21:20', label: '21:20' },
    { value: '21:25', label: '21:25' },
    { value: '21:30', label: '21:30' },
    { value: '21:35', label: '21:35' },
    { value: '21:40', label: '21:40' },
    { value: '21:45', label: '21:45' },
    { value: '21:50', label: '21:50' },
    { value: '21:55', label: '21:55' },
    { value: '22:00', label: '22:00' },
    { value: '22:05', label: '22:05' },
    { value: '22:10', label: '22:10' },
    { value: '22:15', label: '22:15' },
    { value: '22:20', label: '22:20' },
    { value: '22:25', label: '22:25' },
    { value: '22:30', label: '22:30' },
    { value: '22:35', label: '22:35' },
    { value: '22:40', label: '22:40' },
    { value: '22:45', label: '22:45' },
    { value: '22:50', label: '22:50' },
    { value: '22:55', label: '22:55' },
    { value: '23:00', label: '23:00' },
    { value: '23:05', label: '23:05' },
    { value: '23:10', label: '23:10' },
    { value: '23:15', label: '23:15' },
    { value: '23:20', label: '23:20' },
    { value: '23:25', label: '23:25' },
    { value: '23:30', label: '23:30' },
    { value: '23:35', label: '23:35' },
    { value: '23:40', label: '23:40' },
    { value: '23:45', label: '23:45' },
    { value: '23:50', label: '23:50' },
    { value: '23:55', label: '23:55' },
    { value: '24:00', label: '24:00' },
];

export const MAX_BOOK_IN_FUTURE_OPTIONS = [
    { value: 1440, label: '1 day' },
    { value: 2880, label: '2 days' },
    { value: 4320, label: '3 days' },
    { value: 10080, label: '7 days' },
    { value: 20160, label: '2 weeks' },
    { value: 30240, label: '3 weeks' },
    { value: 43200, label: '1 month' },
    { value: 86400, label: '2 months' },
    { value: 129600, label: '3 months' },
    { value: 172800, label: '4 months' },
    { value: 216000, label: '5 months' },
    { value: 259200, label: '6 months' },
];

export const MIN_BOOK_TIME_BEFORE_OPTIONS = [
    { value: 60, label: '1 hour' },
    { value: 120, label: '2 hours' },
    { value: 180, label: '3 hours' },
    { value: 360, label: '6 hours' },
    { value: 720, label: '12 hours' },
    { value: 1440, label: '24 hours' },
    { value: 2160, label: '36 hours' },
    { value: 2880, label: '48 hours' },
];

export const CANCELATION_TIME_OPTIONS = [
    { value: 60, label: '1 hour' },
    { value: 120, label: '2 hours' },
    { value: 180, label: '3 hours' },
    { value: 360, label: '6 hours' },
    { value: 720, label: '12 hours' },
    { value: 1440, label: '24 hours' },
    { value: 2160, label: '36 hours' },
    { value: 2880, label: '48 hours' },
];

export const TIME_SLOT_OPTIONS = [
    { value: 10, label: '10 min' },
    { value: 15, label: '15 min' },
    { value: 20, label: '20 min' },
    { value: 25, label: '25 min' },
    { value: 30, label: '30 min' },
    { value: 35, label: '35 min' },
    { value: 40, label: '40 min' },
    { value: 45, label: '45 min' },
    { value: 50, label: '50 min' },
    { value: 55, label: '55 min' },
    { value: 60, label: '1 hour' },
];

export const TIMEFORMAT_OPTIONS = [
    {
        label: '24 hour-format (e.g. 16:30)',
        value: TimeFormat.Format24h,
    },
    {
        label: '12 hour-format (e.g. 8:30pm)',
        value: TimeFormat.Format12h,
    },
];

export const TIMEZONE_OPTIONS = [
    {
        value: 'Pacific/Niue',
        label: '(GMT-11:00) Niue',
    },
    {
        value: 'Pacific/Pago_Pago',
        label: '(GMT-11:00) Pago Pago',
    },
    {
        value: 'Pacific/Honolulu',
        label: '(GMT-10:00) Hawaii Time',
    },
    {
        value: 'Pacific/Rarotonga',
        label: '(GMT-10:00) Rarotonga',
    },
    {
        value: 'Pacific/Tahiti',
        label: '(GMT-10:00) Tahiti',
    },
    {
        value: 'Pacific/Marquesas',
        label: '(GMT-09:30) Marquesas',
    },
    {
        value: 'America/Anchorage',
        label: '(GMT-09:00) Alaska Time',
    },
    {
        value: 'Pacific/Gambier',
        label: '(GMT-09:00) Gambier',
    },
    {
        value: 'America/Los_Angeles',
        label: '(GMT-08:00) Pacific Time',
    },
    {
        value: 'America/Tijuana',
        label: '(GMT-08:00) Pacific Time - Tijuana',
    },
    {
        value: 'America/Vancouver',
        label: '(GMT-08:00) Pacific Time - Vancouver',
    },
    {
        value: 'America/Whitehorse',
        label: '(GMT-08:00) Pacific Time - Whitehorse',
    },
    {
        value: 'Pacific/Pitcairn',
        label: '(GMT-08:00) Pitcairn',
    },
    {
        value: 'America/Dawson_Creek',
        label: '(GMT-07:00) Mountain Time - Dawson Creek',
    },
    {
        value: 'America/Denver',
        label: '(GMT-07:00) Mountain Time',
    },
    {
        value: 'America/Edmonton',
        label: '(GMT-07:00) Mountain Time - Edmonton',
    },
    {
        value: 'America/Hermosillo',
        label: '(GMT-07:00) Mountain Time - Hermosillo',
    },
    {
        value: 'America/Mazatlan',
        label: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
    },
    {
        value: 'America/Phoenix',
        label: '(GMT-07:00) Mountain Time - Arizona',
    },
    {
        value: 'America/Yellowknife',
        label: '(GMT-07:00) Mountain Time - Yellowknife',
    },
    {
        value: 'America/Belize',
        label: '(GMT-06:00) Belize',
    },
    {
        value: 'America/Chicago',
        label: '(GMT-06:00) Central Time',
    },
    {
        value: 'America/Costa_Rica',
        label: '(GMT-06:00) Costa Rica',
    },
    {
        value: 'America/El_Salvador',
        label: '(GMT-06:00) El Salvador',
    },
    {
        value: 'America/Guatemala',
        label: '(GMT-06:00) Guatemala',
    },
    {
        value: 'America/Managua',
        label: '(GMT-06:00) Managua',
    },
    {
        value: 'America/Mexico_City',
        label: '(GMT-06:00) Central Time - Mexico City',
    },
    {
        value: 'America/Regina',
        label: '(GMT-06:00) Central Time - Regina',
    },
    {
        value: 'America/Tegucigalpa',
        label: '(GMT-06:00) Central Time - Tegucigalpa',
    },
    {
        value: 'America/Winnipeg',
        label: '(GMT-06:00) Central Time - Winnipeg',
    },
    {
        value: 'Pacific/Galapagos',
        label: '(GMT-06:00) Galapagos',
    },
    {
        value: 'America/Bogota',
        label: '(GMT-05:00) Bogota',
    },
    {
        value: 'America/Cancun',
        label: '(GMT-05:00) America Cancun',
    },
    {
        value: 'America/Cayman',
        label: '(GMT-05:00) Cayman',
    },
    {
        value: 'America/Guayaquil',
        label: '(GMT-05:00) Guayaquil',
    },
    {
        value: 'America/Havana',
        label: '(GMT-05:00) Havana',
    },
    {
        value: 'America/Iqaluit',
        label: '(GMT-05:00) Eastern Time - Iqaluit',
    },
    {
        value: 'America/Jamaica',
        label: '(GMT-05:00) Jamaica',
    },
    {
        value: 'America/Lima',
        label: '(GMT-05:00) Lima',
    },
    {
        value: 'America/Nassau',
        label: '(GMT-05:00) Nassau',
    },
    {
        value: 'America/New_York',
        label: '(GMT-05:00) Eastern Time',
    },
    {
        value: 'America/Panama',
        label: '(GMT-05:00) Panama',
    },
    {
        value: 'America/Port-au-Prince',
        label: '(GMT-05:00) Port-au-Prince',
    },
    {
        value: 'America/Rio_Branco',
        label: '(GMT-05:00) Rio Branco',
    },
    {
        value: 'America/Toronto',
        label: '(GMT-05:00) Eastern Time - Toronto',
    },
    {
        value: 'Pacific/Easter',
        label: '(GMT-05:00) Easter Island',
    },
    {
        value: 'America/Caracas',
        label: '(GMT-04:00) Caracas',
    },
    {
        value: 'America/Asuncion',
        label: '(GMT-03:00) Asuncion',
    },
    {
        value: 'America/Barbados',
        label: '(GMT-04:00) Barbados',
    },
    {
        value: 'America/Boa_Vista',
        label: '(GMT-04:00) Boa Vista',
    },
    {
        value: 'America/Campo_Grande',
        label: '(GMT-03:00) Campo Grande',
    },
    {
        value: 'America/Cuiaba',
        label: '(GMT-03:00) Cuiaba',
    },
    {
        value: 'America/Curacao',
        label: '(GMT-04:00) Curacao',
    },
    {
        value: 'America/Grand_Turk',
        label: '(GMT-04:00) Grand Turk',
    },
    {
        value: 'America/Guyana',
        label: '(GMT-04:00) Guyana',
    },
    {
        value: 'America/Halifax',
        label: '(GMT-04:00) Atlantic Time - Halifax',
    },
    {
        value: 'America/La_Paz',
        label: '(GMT-04:00) La Paz',
    },
    {
        value: 'America/Manaus',
        label: '(GMT-04:00) Manaus',
    },
    {
        value: 'America/Martinique',
        label: '(GMT-04:00) Martinique',
    },
    {
        value: 'America/Port_of_Spain',
        label: '(GMT-04:00) Port of Spain',
    },
    {
        value: 'America/Porto_Velho',
        label: '(GMT-04:00) Porto Velho',
    },
    {
        value: 'America/Puerto_Rico',
        label: '(GMT-04:00) Puerto Rico',
    },
    {
        value: 'America/Santo_Domingo',
        label: '(GMT-04:00) Santo Domingo',
    },
    {
        value: 'America/Thule',
        label: '(GMT-04:00) Thule',
    },
    {
        value: 'Atlantic/Bermuda',
        label: '(GMT-04:00) Bermuda',
    },
    {
        value: 'America/St_Johns',
        label: '(GMT-03:30) Newfoundland Time - St. Johns',
    },
    {
        value: 'America/Araguaina',
        label: '(GMT-03:00) Araguaina',
    },
    {
        value: 'America/Argentina/Buenos_Aires',
        label: '(GMT-03:00) Buenos Aires',
    },
    {
        value: 'America/Bahia',
        label: '(GMT-03:00) Salvador',
    },
    {
        value: 'America/Belem',
        label: '(GMT-03:00) Belem',
    },
    {
        value: 'America/Cayenne',
        label: '(GMT-03:00) Cayenne',
    },
    {
        value: 'America/Fortaleza',
        label: '(GMT-03:00) Fortaleza',
    },
    {
        value: 'America/Godthab',
        label: '(GMT-03:00) Godthab',
    },
    {
        value: 'America/Maceio',
        label: '(GMT-03:00) Maceio',
    },
    {
        value: 'America/Miquelon',
        label: '(GMT-03:00) Miquelon',
    },
    {
        value: 'America/Montevideo',
        label: '(GMT-03:00) Montevideo',
    },
    {
        value: 'America/Paramaribo',
        label: '(GMT-03:00) Paramaribo',
    },
    {
        value: 'America/Recife',
        label: '(GMT-03:00) Recife',
    },
    {
        value: 'America/Santiago',
        label: '(GMT-03:00) Santiago',
    },
    {
        value: 'America/Sao_Paulo',
        label: '(GMT-03:00) Sao Paulo',
    },
    {
        value: 'Antarctica/Palmer',
        label: '(GMT-03:00) Palmer',
    },
    {
        value: 'Antarctica/Rothera',
        label: '(GMT-03:00) Rothera',
    },
    {
        value: 'Atlantic/Stanley',
        label: '(GMT-03:00) Stanley',
    },
    {
        value: 'America/Noronha',
        label: '(GMT-02:00) Noronha',
    },
    {
        value: 'Atlantic/South_Georgia',
        label: '(GMT-02:00) South Georgia',
    },
    {
        value: 'America/Scoresbysund',
        label: '(GMT-01:00) Scoresbysund',
    },
    {
        value: 'Atlantic/Azores',
        label: '(GMT-01:00) Azores',
    },
    {
        value: 'Atlantic/Cape_Verde',
        label: '(GMT-01:00) Cape Verde',
    },
    {
        value: 'Africa/Abidjan',
        label: '(GMT+00:00) Abidjan',
    },
    {
        value: 'Africa/Accra',
        label: '(GMT+00:00) Accra',
    },
    {
        value: 'Africa/Bissau',
        label: '(GMT+00:00) Bissau',
    },
    {
        value: 'Africa/Casablanca',
        label: '(GMT+00:00) Casablanca',
    },
    {
        value: 'Africa/El_Aaiun',
        label: '(GMT+00:00) El Aaiun',
    },
    {
        value: 'Africa/Monrovia',
        label: '(GMT+00:00) Monrovia',
    },
    {
        value: 'America/Danmarkshavn',
        label: '(GMT+00:00) Danmarkshavn',
    },
    {
        value: 'Atlantic/Canary',
        label: '(GMT+00:00) Canary Islands',
    },
    {
        value: 'Atlantic/Faroe',
        label: '(GMT+00:00) Faeroe',
    },
    {
        value: 'Atlantic/Reykjavik',
        label: '(GMT+00:00) Reykjavik',
    },
    {
        value: 'Etc/GMT',
        label: '(GMT+00:00) GMT (no daylight saving)',
    },
    {
        value: 'Europe/Dublin',
        label: '(GMT+00:00) Dublin',
    },
    {
        value: 'Europe/Lisbon',
        label: '(GMT+00:00) Lisbon',
    },
    {
        value: 'Europe/London',
        label: '(GMT+00:00) London',
    },
    {
        value: 'Africa/Algiers',
        label: '(GMT+01:00) Algiers',
    },
    {
        value: 'Africa/Ceuta',
        label: '(GMT+01:00) Ceuta',
    },
    {
        value: 'Africa/Lagos',
        label: '(GMT+01:00) Lagos',
    },
    {
        value: 'Africa/Ndjamena',
        label: '(GMT+01:00) Ndjamena',
    },
    {
        value: 'Africa/Tunis',
        label: '(GMT+01:00) Tunis',
    },
    {
        value: 'Africa/Windhoek',
        label: '(GMT+02:00) Windhoek',
    },
    {
        value: 'Europe/Amsterdam',
        label: '(GMT+01:00) Amsterdam',
    },
    {
        value: 'Europe/Andorra',
        label: '(GMT+01:00) Andorra',
    },
    {
        value: 'Europe/Belgrade',
        label: '(GMT+01:00) Central European Time - Belgrade',
    },
    {
        value: 'Europe/Berlin',
        label: '(GMT+01:00) Berlin',
    },
    {
        value: 'Europe/Brussels',
        label: '(GMT+01:00) Brussels',
    },
    {
        value: 'Europe/Budapest',
        label: '(GMT+01:00) Budapest',
    },
    {
        value: 'Europe/Copenhagen',
        label: '(GMT+01:00) Copenhagen',
    },
    {
        value: 'Europe/Gibraltar',
        label: '(GMT+01:00) Gibraltar',
    },
    {
        value: 'Europe/Luxembourg',
        label: '(GMT+01:00) Luxembourg',
    },
    {
        value: 'Europe/Madrid',
        label: '(GMT+01:00) Madrid',
    },
    {
        value: 'Europe/Malta',
        label: '(GMT+01:00) Malta',
    },
    {
        value: 'Europe/Monaco',
        label: '(GMT+01:00) Monaco',
    },
    {
        value: 'Europe/Oslo',
        label: '(GMT+01:00) Oslo',
    },
    {
        value: 'Europe/Paris',
        label: '(GMT+01:00) Paris',
    },
    {
        value: 'Europe/Prague',
        label: '(GMT+01:00) Central European Time - Prague',
    },
    {
        value: 'Europe/Rome',
        label: '(GMT+01:00) Rome',
    },
    {
        value: 'Europe/Stockholm',
        label: '(GMT+01:00) Stockholm',
    },
    {
        value: 'Europe/Tirane',
        label: '(GMT+01:00) Tirane',
    },
    {
        value: 'Europe/Vienna',
        label: '(GMT+01:00) Vienna',
    },
    {
        value: 'Europe/Warsaw',
        label: '(GMT+01:00) Warsaw',
    },
    {
        value: 'Europe/Zurich',
        label: '(GMT+01:00) Zurich',
    },
    {
        value: 'Africa/Cairo',
        label: '(GMT+02:00) Cairo',
    },
    {
        value: 'Africa/Johannesburg',
        label: '(GMT+02:00) Johannesburg',
    },
    {
        value: 'Africa/Maputo',
        label: '(GMT+02:00) Maputo',
    },
    {
        value: 'Africa/Tripoli',
        label: '(GMT+02:00) Tripoli',
    },
    {
        value: 'Asia/Amman',
        label: '(GMT+02:00) Amman',
    },
    {
        value: 'Asia/Beirut',
        label: '(GMT+02:00) Beirut',
    },
    {
        value: 'Asia/Damascus',
        label: '(GMT+02:00) Damascus',
    },
    {
        value: 'Asia/Gaza',
        label: '(GMT+02:00) Gaza',
    },
    {
        value: 'Asia/Jerusalem',
        label: '(GMT+02:00) Jerusalem',
    },
    {
        value: 'Asia/Nicosia',
        label: '(GMT+02:00) Nicosia',
    },
    {
        value: 'Europe/Athens',
        label: '(GMT+02:00) Athens',
    },
    {
        value: 'Europe/Bucharest',
        label: '(GMT+02:00) Bucharest',
    },
    {
        value: 'Europe/Chisinau',
        label: '(GMT+02:00) Chisinau',
    },
    {
        value: 'Europe/Helsinki',
        label: '(GMT+02:00) Helsinki',
    },
    {
        value: 'Europe/Istanbul',
        label: '(GMT+03:00) Istanbul',
    },
    {
        value: 'Europe/Kaliningrad',
        label: '(GMT+02:00) Moscow-01 - Kaliningrad',
    },
    {
        value: 'Europe/Kyiv',
        label: '(GMT+02:00) Kyiv',
    },
    {
        value: 'Europe/Riga',
        label: '(GMT+02:00) Riga',
    },
    {
        value: 'Europe/Sofia',
        label: '(GMT+02:00) Sofia',
    },
    {
        value: 'Europe/Tallinn',
        label: '(GMT+02:00) Tallinn',
    },
    {
        value: 'Europe/Vilnius',
        label: '(GMT+02:00) Vilnius',
    },
    {
        value: 'Africa/Khartoum',
        label: '(GMT+03:00) Khartoum',
    },
    {
        value: 'Africa/Nairobi',
        label: '(GMT+03:00) Nairobi',
    },
    {
        value: 'Antarctica/Syowa',
        label: '(GMT+03:00) Syowa',
    },
    {
        value: 'Asia/Baghdad',
        label: '(GMT+03:00) Baghdad',
    },
    {
        value: 'Asia/Qatar',
        label: '(GMT+03:00) Qatar',
    },
    {
        value: 'Asia/Riyadh',
        label: '(GMT+03:00) Riyadh',
    },
    {
        value: 'Europe/Minsk',
        label: '(GMT+03:00) Minsk',
    },
    {
        value: 'Europe/Moscow',
        label: '(GMT+03:00) Moscow+00 - Moscow',
    },
    {
        value: 'Asia/Tehran',
        label: '(GMT+03:30) Tehran',
    },
    {
        value: 'Asia/Baku',
        label: '(GMT+04:00) Baku',
    },
    {
        value: 'Asia/Dubai',
        label: '(GMT+04:00) Dubai',
    },
    {
        value: 'Asia/Tbilisi',
        label: '(GMT+04:00) Tbilisi',
    },
    {
        value: 'Asia/Yerevan',
        label: '(GMT+04:00) Yerevan',
    },
    {
        value: 'Europe/Samara',
        label: '(GMT+04:00) Moscow+01 - Samara',
    },
    {
        value: 'Indian/Mahe',
        label: '(GMT+04:00) Mahe',
    },
    {
        value: 'Indian/Mauritius',
        label: '(GMT+04:00) Mauritius',
    },
    {
        value: 'Indian/Reunion',
        label: '(GMT+04:00) Reunion',
    },
    {
        value: 'Asia/Kabul',
        label: '(GMT+04:30) Kabul',
    },
    {
        value: 'Antarctica/Mawson',
        label: '(GMT+05:00) Mawson',
    },
    {
        value: 'Asia/Aqtau',
        label: '(GMT+05:00) Aqtau',
    },
    {
        value: 'Asia/Aqtobe',
        label: '(GMT+05:00) Aqtobe',
    },
    {
        value: 'Asia/Ashgabat',
        label: '(GMT+05:00) Ashgabat',
    },
    {
        value: 'Asia/Dushanbe',
        label: '(GMT+05:00) Dushanbe',
    },
    {
        value: 'Asia/Karachi',
        label: '(GMT+05:00) Karachi',
    },
    {
        value: 'Asia/Tashkent',
        label: '(GMT+05:00) Tashkent',
    },
    {
        value: 'Asia/Yekaterinburg',
        label: '(GMT+05:00) Moscow+02 - Yekaterinburg',
    },
    {
        value: 'Indian/Kerguelen',
        label: '(GMT+05:00) Kerguelen',
    },
    {
        value: 'Indian/Maldives',
        label: '(GMT+05:00) Maldives',
    },
    {
        value: 'Asia/Calcutta',
        label: '(GMT+05:30) India Standard Time',
    },
    {
        value: 'Asia/Colombo',
        label: '(GMT+05:30) Colombo',
    },
    {
        value: 'Asia/Katmandu',
        label: '(GMT+05:45) Katmandu',
    },
    {
        value: 'Antarctica/Vostok',
        label: '(GMT+06:00) Vostok',
    },
    {
        value: 'Asia/Almaty',
        label: '(GMT+06:00) Almaty',
    },
    {
        value: 'Asia/Bishkek',
        label: '(GMT+06:00) Bishkek',
    },
    {
        value: 'Asia/Dhaka',
        label: '(GMT+06:00) Dhaka',
    },
    {
        value: 'Asia/Omsk',
        label: '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk',
    },
    {
        value: 'Asia/Thimphu',
        label: '(GMT+06:00) Thimphu',
    },
    {
        value: 'Indian/Chagos',
        label: '(GMT+06:00) Chagos',
    },
    {
        value: 'Asia/Rangoon',
        label: '(GMT+06:30) Rangoon',
    },
    {
        value: 'Indian/Cocos',
        label: '(GMT+06:30) Cocos',
    },
    {
        value: 'Antarctica/Davis',
        label: '(GMT+07:00) Davis',
    },
    {
        value: 'Asia/Bangkok',
        label: '(GMT+07:00) Bangkok',
    },
    {
        value: 'Asia/Hovd',
        label: '(GMT+07:00) Hovd',
    },
    {
        value: 'Asia/Jakarta',
        label: '(GMT+07:00) Jakarta',
    },
    {
        value: 'Asia/Krasnoyarsk',
        label: '(GMT+07:00) Moscow+04 - Krasnoyarsk',
    },
    {
        value: 'Asia/Saigon',
        label: '(GMT+07:00) Hanoi',
    },
    {
        value: 'Asia/Ho_Chi_Minh',
        label: '(GMT+07:00) Ho Chi Minh',
    },
    {
        value: 'Indian/Christmas',
        label: '(GMT+07:00) Christmas',
    },
    {
        value: 'Antarctica/Casey',
        label: '(GMT+08:00) Casey',
    },
    {
        value: 'Asia/Brunei',
        label: '(GMT+08:00) Brunei',
    },
    {
        value: 'Asia/Choibalsan',
        label: '(GMT+08:00) Choibalsan',
    },
    {
        value: 'Asia/Hong_Kong',
        label: '(GMT+08:00) Hong Kong',
    },
    {
        value: 'Asia/Irkutsk',
        label: '(GMT+08:00) Moscow+05 - Irkutsk',
    },
    {
        value: 'Asia/Kuala_Lumpur',
        label: '(GMT+08:00) Kuala Lumpur',
    },
    {
        value: 'Asia/Macau',
        label: '(GMT+08:00) Macau',
    },
    {
        value: 'Asia/Makassar',
        label: '(GMT+08:00) Makassar',
    },
    {
        value: 'Asia/Manila',
        label: '(GMT+08:00) Manila',
    },
    {
        value: 'Asia/Shanghai',
        label: '(GMT+08:00) China Time - Beijing',
    },
    {
        value: 'Asia/Singapore',
        label: '(GMT+08:00) Singapore',
    },
    {
        value: 'Asia/Taipei',
        label: '(GMT+08:00) Taipei',
    },
    {
        value: 'Asia/Ulaanbaatar',
        label: '(GMT+08:00) Ulaanbaatar',
    },
    {
        value: 'Australia/Perth',
        label: '(GMT+08:00) Western Time - Perth',
    },
    {
        value: 'Asia/Pyongyang',
        label: '(GMT+08:30) Pyongyang',
    },
    {
        value: 'Asia/Dili',
        label: '(GMT+09:00) Dili',
    },
    {
        value: 'Asia/Jayapura',
        label: '(GMT+09:00) Jayapura',
    },
    {
        value: 'Asia/Seoul',
        label: '(GMT+09:00) Seoul',
    },
    {
        value: 'Asia/Tokyo',
        label: '(GMT+09:00) Tokyo',
    },
    {
        value: 'Asia/Yakutsk',
        label: '(GMT+09:00) Moscow+06 - Yakutsk',
    },
    {
        value: 'Pacific/Palau',
        label: '(GMT+09:00) Palau',
    },
    {
        value: 'Australia/Adelaide',
        label: '(GMT+10:30) Central Time - Adelaide',
    },
    {
        value: 'Australia/Darwin',
        label: '(GMT+09:30) Central Time - Darwin',
    },
    {
        value: 'Antarctica/DumontDUrville',
        label: "(GMT+10:00) Dumont D'Urville",
    },
    {
        value: 'Asia/Magadan',
        label: '(GMT+10:00) Moscow+07 - Magadan',
    },
    {
        value: 'Asia/Vladivostok',
        label: '(GMT+10:00) Moscow+07 - Vladivostok',
    },
    {
        value: 'Australia/Brisbane',
        label: '(GMT+10:00) Eastern Time - Brisbane',
    },
    {
        value: 'Asia/Yuzhno-Sakhalinsk',
        label: '(GMT+11:00) Moscow+08 - Yuzhno-Sakhalinsk',
    },
    {
        value: 'Australia/Hobart',
        label: '(GMT+11:00) Eastern Time - Hobart',
    },
    {
        value: 'Australia/Sydney',
        label: '(GMT+11:00) Eastern Time - Melbourne, Sydney',
    },
    {
        value: 'Pacific/Chuuk',
        label: '(GMT+10:00) Truk',
    },
    {
        value: 'Pacific/Guam',
        label: '(GMT+10:00) Guam',
    },
    {
        value: 'Pacific/Port_Moresby',
        label: '(GMT+10:00) Port Moresby',
    },
    {
        value: 'Pacific/Efate',
        label: '(GMT+11:00) Efate',
    },
    {
        value: 'Pacific/Guadalcanal',
        label: '(GMT+11:00) Guadalcanal',
    },
    {
        value: 'Pacific/Kosrae',
        label: '(GMT+11:00) Kosrae',
    },
    {
        value: 'Pacific/Norfolk',
        label: '(GMT+11:00) Norfolk',
    },
    {
        value: 'Pacific/Noumea',
        label: '(GMT+11:00) Noumea',
    },
    {
        value: 'Pacific/Pohnpei',
        label: '(GMT+11:00) Ponape',
    },
    {
        value: 'Asia/Kamchatka',
        label: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
    },
    {
        value: 'Pacific/Auckland',
        label: '(GMT+13:00) Auckland',
    },
    {
        value: 'Pacific/Fiji',
        label: '(GMT+13:00) Fiji',
    },
    {
        value: 'Pacific/Funafuti',
        label: '(GMT+12:00) Funafuti',
    },
    {
        value: 'Pacific/Kwajalein',
        label: '(GMT+12:00) Kwajalein',
    },
    {
        value: 'Pacific/Majuro',
        label: '(GMT+12:00) Majuro',
    },
    {
        value: 'Pacific/Nauru',
        label: '(GMT+12:00) Nauru',
    },
    {
        value: 'Pacific/Tarawa',
        label: '(GMT+12:00) Tarawa',
    },
    {
        value: 'Pacific/Wake',
        label: '(GMT+12:00) Wake',
    },
    {
        value: 'Pacific/Wallis',
        label: '(GMT+12:00) Wallis',
    },
    {
        value: 'Pacific/Apia',
        label: '(GMT+14:00) Apia',
    },
    {
        value: 'Pacific/Enderbury',
        label: '(GMT+13:00) Enderbury',
    },
    {
        value: 'Pacific/Fakaofo',
        label: '(GMT+13:00) Fakaofo',
    },
    {
        value: 'Pacific/Tongatapu',
        label: '(GMT+13:00) Tongatapu',
    },
    {
        value: 'Pacific/Kiritimati',
        label: '(GMT+14:00) Kiritimati',
    },
];

export const REPEAT_TYPE_OPTIONS = [
    { value: 'DOES_NOT_REPEAT', label: 'repeat.type.option.norepeat' },
    { value: 'EVERY_DAY', label: 'repeat.type.option.everyday' },
    { value: 'EVERY_WEEK', label: 'repeat.type.option.everyweek' },
    { value: 'EVERY_MONTH', label: 'repeat.type.option.everymonth' },
    { value: 'CUSTOM', label: 'repeat.type.option.custom' },
];

export const END_TYPE_OPTIONS = [
    { value: 'SPECIFIC_DATE', label: 'end.type.option.specific.date' },
    { value: 'AFTER', label: 'end.type.option.after' },
];

export const FREQUENCY_TYPE_OPTIONS = [
    { value: 'DAY', label: 'frequency.type.option.day' },
    { value: 'WEEK', label: 'frequency.type.option.week' },
    { value: 'MONTH', label: 'frequency.type.option.month' },
];
