import { Business, Rent } from '@bookinbio/interface';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getRentsByBusiness = async (
    business?: Business,
): Promise<Rent[]> => {
    if (!business) {
        throw new Error('No business was provided');
    }

    const businessDoc = doc(businessesColl, business.id);
    const q = query(
        collection(doc(businessesColl, businessDoc.id), 'rents'),
        where('allowBookings', '==', true),
    );
    const rentsSnapshot = await getDocs(q);

    if (rentsSnapshot.empty) {
        return [];
    }

    return rentsSnapshot.docs.map(
        (rentDoc) => ({ id: rentDoc.id, ...rentDoc.data() } as Rent),
    );
};
