import { BusinessType } from '@bookinbio/api';

export const BUSINESS_TYPE_OPTIONS = [
    { label: 'Barbers', value: BusinessType.Barbers },
    { label: 'Hair salons', value: BusinessType.HairSalons },
    { label: 'Spa wellness', value: BusinessType.SpaWellness },
    { label: 'Beauty salons', value: BusinessType.BeautySalons },
    { label: 'Personal trainer', value: BusinessType.PersonalTrainers },
    { label: 'Sports Facilities', value: BusinessType.SportsFacilities },
    { label: 'Physiotherapists', value: BusinessType.PhysioTherapists },
    { label: 'Message therapists', value: BusinessType.MassageTherapists },
    { label: 'Photographs', value: BusinessType.Photographs },
    { label: 'None', value: null },
];
