import {
    AppointmentStatusType,
    EliminateGapsStrategy,
    OverrideType,
    ServiceType,
    TimeSlotCreationStrategy,
} from '@bookinbio/enums';
import {
    Appointment,
    Business,
    DayNumbers,
    DaySetting,
    GoogleEvent,
    Override,
    Professional,
    Rent,
    Service,
    User,
    WorkHours,
} from '@bookinbio/interface';
import {
    addMinutes,
    differenceInMinutes,
    endOfDay,
    format,
    getDay,
    isAfter,
    isBefore,
    isEqual,
    isSameHour,
    isSameMinute,
    isWithinInterval,
    parseISO,
    setHours,
    setMinutes,
    startOfDay,
    startOfWeek,
    subMinutes,
} from 'date-fns';
import {
    collection,
    doc,
    DocumentData,
    getDocs,
    query,
    QuerySnapshot,
    where,
} from 'firebase/firestore';

import { getDayGoogleEvents } from '../callable';
import { businessesColl, usersColl } from '../collections';

export interface TimeSlot {
    start: Date;
    end: Date;
    ownerId?: string;
}

const parseGoogleEvents = (
    googleEvents: GoogleEvent[],
    selectedDate: Date,
): TimeSlot[] => {
    return googleEvents.map((event) => {
        const eventStart = event.start ? parseISO(event.start) : selectedDate;
        const eventEnd = addMinutes(eventStart, event.duration);
        return { start: eventStart, end: eventEnd };
    });
};

export const getAvailableTimeSlots2 = async ({
    selectedDate,
    business,
    professionals,
    services,
    serviceIds,
    serviceType,
    professionalId,
    rents,
    rentIds,
}: {
    selectedDate: Date;
    professionals?: Professional[];
    business?: Business | null;
    services?: Service[];
    serviceType: string | null;
    rents?: Rent[];
    locationId?: string;
    professionalId?: string;
    serviceIds?: string[];
    rentIds?: string[];
}): Promise<TimeSlot[]> => {
    if (!business || !services || !serviceType) {
        throw new Error('Missing locations, business, services or serviceType');
    }

    const timeSlotSize = business.bookingSettings?.timeSlotSize ?? 30;
    const restTimeBeforeAppointment =
        business.bookingSettings?.restTimeBeforeAppointment ?? 5;
    const restTimeAfterAppointment =
        business.bookingSettings?.restTimeAfterAppointment ?? 5;
    const professionalSelectionEnabled =
        business.bookingSettings?.professionalSelectionEnabled ?? false;
    const timeSlotCreationStrategy =
        business.bookingSettings?.timeSlotCreationStrategy ??
        TimeSlotCreationStrategy.ReduceCalendarGaps;
    const noBookBeforeTime = business.bookingSettings?.beforeBookTime ?? 180;
    const eliminateTimeSlotStrategy =
        business.bookingSettings?.eliminateTimeSlotStrategy ??
        EliminateGapsStrategy.StartEndOfDay;
    const servicesDuration = services.reduce((prev, curr) => {
        if (serviceIds?.includes(curr.id)) {
            return prev + curr.serviceSettings.duration;
        }
        return prev;
    }, 0);
    // const serviceLimitDay = services[0].serviceSettings.dayLimitBookings;
    const selectedServiceId = serviceIds ? serviceIds[0] : 'random';
    const selectedService = services.find((s) => s.id === selectedServiceId);
    const serviceLimitDay = selectedService
        ? selectedService.serviceSettings.dayLimitBookings
        : null;

    // Professional is selected
    if (
        serviceType === ServiceType.Service &&
        professionalSelectionEnabled &&
        professionalId !== 'random'
    ) {
        if (!professionalId || !professionals) {
            throw new Error('Missing professional id for SERVICE type');
        }

        const professional = professionals.find(
            (prof) => prof.id === professionalId,
        );

        if (!professional || !professional.calendarSettings) {
            throw new Error('Missing professional for SERVICE type');
        }

        const result = await getProfessionalAvailableTimeSlots({
            business,
            professional,
            restTimeAfterAppointment,
            restTimeBeforeAppointment,
            noBookBeforeTime,
            selectedDate,
            servicesDuration,
            serviceLimitDay,
            selectedServiceId,
            timeSlotSize,
            timeSlotCreationStrategy,
            eliminateTimeSlotStrategy,
        });

        return result.timeSlots;
    }

    // All professionals are considered
    if (
        serviceType === ServiceType.Service &&
        (!professionalSelectionEnabled || professionalId === 'random')
    ) {
        if (!professionals) {
            throw new Error('Missing professionals for SERVICE type');
        }

        const promises = [];
        for (const professional of professionals) {
            promises.push(
                getProfessionalAvailableTimeSlots({
                    business,
                    professional,
                    restTimeAfterAppointment,
                    restTimeBeforeAppointment,
                    noBookBeforeTime,
                    selectedDate,
                    servicesDuration,
                    serviceLimitDay,
                    selectedServiceId,
                    timeSlotSize,
                    timeSlotCreationStrategy,
                    eliminateTimeSlotStrategy,
                }),
            );
        }

        const profsAvailableTimeSlots = await Promise.all(promises);

        return mergeAndPrioritizeTimeSlots(profsAvailableTimeSlots);
    }

    // Selected rents places
    if (serviceType === ServiceType.Rent && rentIds) {
        if (!rents) {
            throw new Error('Missing rents for RENT type');
        }

        const promises = [];
        for (const rent of rents) {
            if (!rentIds.includes(rent.id)) {
                continue;
            }

            promises.push(
                getRentsAvailableTimeSlots({
                    business,
                    rent,
                    restTimeAfterAppointment,
                    restTimeBeforeAppointment,
                    noBookBeforeTime,
                    selectedDate,
                    servicesDuration,
                    serviceLimitDay,
                    selectedServiceId,
                    timeSlotSize,
                    timeSlotCreationStrategy,
                    eliminateTimeSlotStrategy,
                }),
            );
        }

        const result = await Promise.all(promises);

        return intersectTimeSlots(result);
    }

    return [];
};

const mergeAndPrioritizeTimeSlots = (
    profsAvailableTimeSlots: {
        timeSlots: TimeSlot[];
        appointmentsCount: number;
    }[],
): TimeSlot[] => {
    const slotMap = new Map<string, TimeSlot>(); // For merging slots
    const appointmentsCountMap = new Map<string, number>(); // For counting appointments

    for (const { timeSlots, appointmentsCount } of profsAvailableTimeSlots) {
        for (const slot of timeSlots) {
            const slotKey = `${slot.start.toISOString()} - ${slot.end.toISOString()}`;

            if (
                !slotMap.has(slotKey) ||
                (appointmentsCountMap.get(slot.ownerId ?? 'id') ?? Infinity) >
                    appointmentsCount
            ) {
                slotMap.set(slotKey, slot);
                appointmentsCountMap.set(
                    slot.ownerId ?? 'id',
                    appointmentsCount,
                );
            }
        }
    }

    return Array.from(slotMap.values()).sort((a, b) => {
        return +a.start - +b.start;
    });
};

const intersectTimeSlots = (allAvailableSlots: TimeSlot[][]): TimeSlot[] => {
    // This map will keep track of the count of each timeslot across different arrays
    const slotCountMap = new Map<string, number>();

    allAvailableSlots.forEach((slots, index) => {
        slots.forEach((slot) => {
            const key = `${slot.start.toISOString()} - ${slot.end.toISOString()}`;

            if (index === 0) {
                // Initialize count for the first array
                slotCountMap.set(key, 1);
            } else {
                // Increment count if the slot is already in the map
                const slot = slotCountMap.get(key);
                if (slotCountMap.has(key) && slot) {
                    slotCountMap.set(key, slot + 1);
                }
            }
        });
    });

    // Filter out slots that are present in all arrays
    const commonSlots: TimeSlot[] = [];
    slotCountMap.forEach((count, key) => {
        if (count === allAvailableSlots.length) {
            const [start, end] = key.split(' - ');
            commonSlots.push({ start: new Date(start), end: new Date(end) });
        }
    });

    return commonSlots;
};

const getProfessionalsWorkingHours = async (
    userId: string,
    selectDate: Date,
) => {
    const userWhColl = collection(doc(usersColl, userId), 'working-hours');

    const start = startOfDay(startOfWeek(selectDate, { weekStartsOn: 1 }));

    const q = query(userWhColl, where('startDate', '==', start));
    const workingHoursSnaps = await getDocs(q);

    if (workingHoursSnaps.empty) {
        return null;
    }

    return workingHoursSnaps.docs[0].data().daySettings as Record<
        DayNumbers,
        DaySetting
    >;
};

const getProfessionalAvailableTimeSlots = async ({
    professional,
    selectedDate,
    timeSlotSize,
    noBookBeforeTime,
    restTimeAfterAppointment,
    restTimeBeforeAppointment,
    timeSlotCreationStrategy,
    eliminateTimeSlotStrategy,
    servicesDuration,
    serviceLimitDay,
    selectedServiceId,
    business,
}: {
    professional: User;
    selectedDate: Date;
    timeSlotSize: number;
    restTimeBeforeAppointment: number;
    restTimeAfterAppointment: number;
    noBookBeforeTime: number;
    timeSlotCreationStrategy: string;
    eliminateTimeSlotStrategy: string;
    servicesDuration: number;
    serviceLimitDay?: number | null;
    selectedServiceId: string;
    business: Business;
}): Promise<{ timeSlots: TimeSlot[]; appointmentsCount: number }> => {
    if (!professional.calendarSettings) {
        throw new Error(
            'Missing professional calendar settings for SERVICE type',
        );
    }

    const defaultProfessionalWorkingHours =
        professional.calendarSettings.daySettings[getDay(selectedDate)];

    const currentDateWorkingHours = await getProfessionalsWorkingHours(
        professional.id,
        selectedDate,
    );

    const workingHours = currentDateWorkingHours
        ? currentDateWorkingHours[getDay(selectedDate)]
        : defaultProfessionalWorkingHours;

    if (!workingHours.isOpen) {
        return { timeSlots: [], appointmentsCount: 0 };
    }

    const transformedWorkingHours = transformWorkingHoursToTimeSlots(
        workingHours.timeSlots,
        selectedDate,
    );

    const startDay = startOfDay(selectedDate);
    const endDay = endOfDay(selectedDate);

    // Fetch overrides & appointments
    const professionalRef = doc(usersColl, professional.id);

    // Overrides query
    const overridesColl = collection(businessesColl, business.id, 'overrides');
    const overridesQuery = query(
        overridesColl,
        where('professionalRefs', 'array-contains', professionalRef),
        where('start', '>=', startDay),
        where('start', '<=', endDay),
    );
    const getOverrides = getDocs(overridesQuery);

    // Appointments query
    const appointmentsColl = collection(
        businessesColl,
        business.id,
        'appointments',
    );
    const approvedApptsQuery = query(
        appointmentsColl,
        where('professionalRefs', 'array-contains', professionalRef),
        where('status', '==', AppointmentStatusType.Approved),
        where('start', '>=', startDay),
        where('start', '<=', endDay),
    );
    const pendingApptsQuery = query(
        appointmentsColl,
        where('professionalRefs', 'array-contains', professionalRef),
        where('status', '==', AppointmentStatusType.Pending),
        where('start', '>=', startDay),
        where('start', '<=', endDay),
    );
    const proposedApptsQuery = query(
        appointmentsColl,
        where('professionalRefs', 'array-contains', professionalRef),
        where('status', '==', AppointmentStatusType.Proposed),
        where('start', '>=', startDay),
        where('start', '<=', endDay),
    );
    const getApprovedAppointements = getDocs(approvedApptsQuery);
    const getPendingAppointements = getDocs(pendingApptsQuery);
    const getProposedAppointements = getDocs(proposedApptsQuery);
    const getGEvents = professional.googleTokens
        ? getDayGoogleEvents({
              userId: professional.id,
              date: selectedDate.toISOString(),
              timezone: 'Europe/Bratislava',
          })
        : null;

    const [
        approvedApptsSnapshot,
        pendingApptsSnapshot,
        proposedApptsSnapshot,
        overridesSnapshot,
        googleEvents,
    ] = await Promise.all([
        getApprovedAppointements,
        getPendingAppointements,
        getProposedAppointements,
        getOverrides,
        getGEvents,
    ]);

    const workingTimeOverrides: TimeSlot[] = [];
    const offTimeOverrides: TimeSlot[] = [];
    if (!overridesSnapshot.empty) {
        overridesSnapshot.forEach((overrideDoc) => {
            if (overrideDoc.exists()) {
                const override = overrideDoc.data() as Omit<Override, 'id'>;
                if (override.type === OverrideType.WorkingTime) {
                    workingTimeOverrides.push({
                        start: override.start.toDate(),
                        end: addMinutes(
                            override.start.toDate(),
                            override.duration,
                        ),
                    });
                }

                if (override.type === OverrideType.OffTime) {
                    offTimeOverrides.push({
                        start: override.start.toDate(),
                        end: addMinutes(
                            override.start.toDate(),
                            override.duration,
                        ),
                    });
                }
            }
        });
    }

    let appointmentsCount = 0;
    const approvedAppts = transformAppointmentsToTimeSlots(
        approvedApptsSnapshot,
        restTimeBeforeAppointment,
        restTimeAfterAppointment,
    );
    const pendingAppts = transformAppointmentsToTimeSlots(
        pendingApptsSnapshot,
        restTimeBeforeAppointment,
        restTimeAfterAppointment,
    );
    const proposedAppts = transformAppointmentsToTimeSlots(
        proposedApptsSnapshot,
        restTimeBeforeAppointment,
        restTimeAfterAppointment,
    );
    appointmentsCount +=
        approvedAppts.timeSlots.length +
        pendingAppts.timeSlots.length +
        proposedAppts.timeSlots.length;
    appointmentsCount += googleEvents ? googleEvents.data.length : 0;

    // Checker for service limit day per employee
    if (serviceLimitDay) {
        let totalCount =
            approvedAppts.serviceCounts.get(selectedServiceId) ?? 0;
        totalCount += pendingAppts.serviceCounts.get(selectedServiceId) ?? 0;
        totalCount += proposedAppts.serviceCounts.get(selectedServiceId) ?? 0;

        if (totalCount >= serviceLimitDay) {
            return { timeSlots: [], appointmentsCount };
        }
    }

    const parsedGoogleEvents = googleEvents
        ? parseGoogleEvents(googleEvents.data, selectedDate)
        : [];
    const allAppts = [
        ...offTimeOverrides,
        ...approvedAppts.timeSlots,
        ...pendingAppts.timeSlots,
        ...proposedAppts.timeSlots,
        ...parsedGoogleEvents,
    ];

    const adjustedWorkingHours = adjustWorkingHours(
        transformedWorkingHours,
        workingTimeOverrides,
    );
    const filteredWorkingHours = subtractBusyTime(
        adjustedWorkingHours,
        allAppts,
    );

    if (
        timeSlotCreationStrategy === TimeSlotCreationStrategy.ReduceCalendarGaps
    ) {
        return {
            appointmentsCount,
            timeSlots: reduceCalendarGaps(
                filteredWorkingHours,
                servicesDuration,
                noBookBeforeTime,
                timeSlotSize,
                restTimeAfterAppointment,
                restTimeBeforeAppointment,
                professional.id,
                {
                    start: adjustedWorkingHours[0].start,
                    end: adjustedWorkingHours[adjustedWorkingHours.length - 1]
                        .end,
                },
            ),
        };
    }

    if (timeSlotCreationStrategy === TimeSlotCreationStrategy.RegularStrategy) {
        return {
            appointmentsCount,
            timeSlots: regularStrategy(
                filteredWorkingHours,
                servicesDuration,
                noBookBeforeTime,
                timeSlotSize,
                restTimeAfterAppointment,
                restTimeBeforeAppointment,
                professional.id,
                {
                    start: adjustedWorkingHours[0].start,
                    end: adjustedWorkingHours[adjustedWorkingHours.length - 1]
                        .end,
                },
            ),
        };
    }

    if (
        timeSlotCreationStrategy ===
        TimeSlotCreationStrategy.EliminateCalendarGaps
    ) {
        return {
            appointmentsCount,
            timeSlots: eliminateCalendarGaps(
                filteredWorkingHours,
                servicesDuration,
                noBookBeforeTime,
                timeSlotSize,
                restTimeAfterAppointment,
                restTimeBeforeAppointment,
                eliminateTimeSlotStrategy,
                professional.id,
                {
                    start: adjustedWorkingHours[0].start,
                    end: adjustedWorkingHours[adjustedWorkingHours.length - 1]
                        .end,
                },
            ),
        };
    }

    return { timeSlots: [], appointmentsCount: 0 };
};

const getRentsAvailableTimeSlots = async ({
    rent,
    selectedDate,
    timeSlotSize,
    noBookBeforeTime,
    restTimeAfterAppointment,
    restTimeBeforeAppointment,
    timeSlotCreationStrategy,
    eliminateTimeSlotStrategy,
    servicesDuration,
    serviceLimitDay,
    selectedServiceId,
    business,
}: {
    rent: Rent;
    selectedDate: Date;
    timeSlotSize: number;
    restTimeBeforeAppointment: number;
    restTimeAfterAppointment: number;
    noBookBeforeTime: number;
    timeSlotCreationStrategy: string;
    eliminateTimeSlotStrategy: string;
    servicesDuration: number;
    selectedServiceId: string;
    serviceLimitDay?: number | null;
    business: Business;
}): Promise<TimeSlot[]> => {
    if (!rent.workingHours) {
        throw new Error('Missing rent working hours');
    }

    const rentWorkingHours = rent.workingHours[getDay(selectedDate)];

    if (!rentWorkingHours.isOpen) {
        return [];
    }

    const transformedWorkingHours = transformWorkingHoursToTimeSlots(
        rentWorkingHours.timeSlots,
        selectedDate,
    );

    const startDay = startOfDay(selectedDate);
    const endDay = endOfDay(selectedDate);

    // Fetch overrides & appointments
    const rentColl = collection(businessesColl, business.id, 'rents');
    const rentRef = doc(rentColl, rent.id);

    // Overrides query
    const overridesColl = collection(businessesColl, business.id, 'overrides');
    const overridesQuery = query(
        overridesColl,
        where('rentRefs', 'array-contains', rentRef),
        where('start', '>=', startDay),
        where('start', '<=', endDay),
    );
    const getOverrides = getDocs(overridesQuery);

    // Appointments query
    const appointmentsColl = collection(
        businessesColl,
        business.id,
        'appointments',
    );
    const approvedApptsQuery = query(
        appointmentsColl,
        where('rentRefs', 'array-contains', rentRef),
        where('status', '==', AppointmentStatusType.Approved),
        where('start', '>=', startDay),
        where('start', '<=', endDay),
    );
    const pendingApptsQuery = query(
        appointmentsColl,
        where('rentRefs', 'array-contains', rentRef),
        where('status', '==', AppointmentStatusType.Pending),
        where('start', '>=', startDay),
        where('start', '<=', endDay),
    );
    const proposedApptsQuery = query(
        appointmentsColl,
        where('rentRefs', 'array-contains', rentRef),
        where('status', '==', AppointmentStatusType.Proposed),
        where('start', '>=', startDay),
        where('start', '<=', endDay),
    );
    const getApprovedAppointements = getDocs(approvedApptsQuery);
    const getPendingAppointements = getDocs(pendingApptsQuery);
    const getProposedAppointements = getDocs(proposedApptsQuery);

    const [
        approvedApptsSnapshot,
        pendingApptsSnapshot,
        proposedApptsSnapshot,
        overridesSnapshot,
    ] = await Promise.all([
        getApprovedAppointements,
        getPendingAppointements,
        getProposedAppointements,
        getOverrides,
    ]);

    const workingTimeOverrides: TimeSlot[] = [];
    const offTimeOverrides: TimeSlot[] = [];
    if (!overridesSnapshot.empty) {
        overridesSnapshot.forEach((overrideDoc) => {
            if (overrideDoc.exists()) {
                const override = overrideDoc.data() as Omit<Override, 'id'>;
                if (override.type === OverrideType.WorkingTime) {
                    workingTimeOverrides.push({
                        start: override.start.toDate(),
                        end: addMinutes(
                            override.start.toDate(),
                            override.duration,
                        ),
                    });
                }

                if (override.type === OverrideType.OffTime) {
                    offTimeOverrides.push({
                        start: override.start.toDate(),
                        end: addMinutes(
                            override.start.toDate(),
                            override.duration,
                        ),
                    });
                }
            }
        });
    }

    const approvedAppts = transformAppointmentsToTimeSlots(
        approvedApptsSnapshot,
        restTimeBeforeAppointment,
        restTimeAfterAppointment,
    );
    const pendingAppts = transformAppointmentsToTimeSlots(
        pendingApptsSnapshot,
        restTimeBeforeAppointment,
        restTimeAfterAppointment,
    );
    const proposedAppts = transformAppointmentsToTimeSlots(
        proposedApptsSnapshot,
        restTimeBeforeAppointment,
        restTimeAfterAppointment,
    );

    const allAppts = [
        ...offTimeOverrides,
        ...approvedAppts.timeSlots,
        ...pendingAppts.timeSlots,
        ...proposedAppts.timeSlots,
    ];

    const adjustedWorkingHours = adjustWorkingHours(
        transformedWorkingHours,
        workingTimeOverrides,
    );
    const filteredWorkingHours = subtractBusyTime(
        adjustedWorkingHours,
        allAppts,
    );

    if (
        timeSlotCreationStrategy === TimeSlotCreationStrategy.ReduceCalendarGaps
    ) {
        return reduceCalendarGaps(
            filteredWorkingHours,
            servicesDuration,
            noBookBeforeTime,
            timeSlotSize,
            restTimeAfterAppointment,
            restTimeBeforeAppointment,
            rent.id,
            {
                start: adjustedWorkingHours[0].start,
                end: adjustedWorkingHours[adjustedWorkingHours.length - 1].end,
            },
        );
    }

    if (timeSlotCreationStrategy === TimeSlotCreationStrategy.RegularStrategy) {
        return regularStrategy(
            filteredWorkingHours,
            servicesDuration,
            noBookBeforeTime,
            timeSlotSize,
            restTimeAfterAppointment,
            restTimeBeforeAppointment,
            rent.id,
            {
                start: adjustedWorkingHours[0].start,
                end: adjustedWorkingHours[adjustedWorkingHours.length - 1].end,
            },
        );
    }

    if (
        timeSlotCreationStrategy ===
        TimeSlotCreationStrategy.EliminateCalendarGaps
    ) {
        return eliminateCalendarGaps(
            filteredWorkingHours,
            servicesDuration,
            noBookBeforeTime,
            timeSlotSize,
            restTimeAfterAppointment,
            restTimeBeforeAppointment,
            eliminateTimeSlotStrategy,
            rent.id,
            {
                start: adjustedWorkingHours[0].start,
                end: adjustedWorkingHours[adjustedWorkingHours.length - 1].end,
            },
        );
    }

    return [];
};

const transformAppointmentsToTimeSlots = (
    apptsSnapshot: QuerySnapshot<DocumentData, DocumentData>,
    restTimeBeforeAppointment: number,
    restTimeAfterAppointment: number,
) => {
    if (apptsSnapshot.empty) {
        return { timeSlots: [], serviceCounts: new Map<string, number>() };
    }

    const appts: TimeSlot[] = [];
    const serviceCounts = new Map<string, number>();
    apptsSnapshot.forEach((apptDoc) => {
        if (apptDoc.exists()) {
            const appointment = apptDoc.data() as Omit<Appointment, 'id'>;

            appointment.serviceDatas.forEach((serviceData) => {
                serviceCounts.set(
                    serviceData.id,
                    (serviceCounts.get(serviceData.id) || 0) + 1,
                );
            });

            appts.push({
                start: subMinutes(
                    appointment.start.toDate(),
                    restTimeBeforeAppointment,
                ),
                end: addMinutes(
                    appointment.start.toDate(),
                    appointment.duration + restTimeAfterAppointment,
                ),
            });
        }
    });

    return { timeSlots: appts, serviceCounts: serviceCounts };
};

const transformWorkingHoursToTimeSlots = (
    timeSlots: WorkHours[],
    date: Date,
) => {
    return timeSlots.map((slot) => ({
        start: parseISO(`${format(date, 'yyyy-MM-dd')}T${slot.start}:00`),
        end: parseISO(`${format(date, 'yyyy-MM-dd')}T${slot.end}:00`),
    }));
};

const adjustWorkingHours = (
    workingHours: TimeSlot[],
    workingTimeOverrides: TimeSlot[],
): TimeSlot[] => {
    let adjustedHours = [...workingHours];

    // Apply each working time override to the working hours
    for (const override of workingTimeOverrides) {
        adjustedHours = adjustedHours.flatMap((wh) => {
            if (
                isWithinInterval(override.start, {
                    start: wh.start,
                    end: wh.end,
                }) ||
                isWithinInterval(override.end, {
                    start: wh.start,
                    end: wh.end,
                }) ||
                (override.start < wh.start && override.end > wh.end)
            ) {
                // Override affects this working hour slot
                const newStart =
                    override.start > wh.start ? override.start : wh.start;
                const newEnd = override.end < wh.end ? override.end : wh.end;
                return [{ start: newStart, end: newEnd }];
            }
            return [wh]; // No effect from this override, keep original working hour
        });
    }

    return adjustedHours;
};

const subtractBusyTime = (
    workingHours: TimeSlot[],
    busyTimes: TimeSlot[],
): TimeSlot[] => {
    let filteredWorkingHours = [...workingHours];
    busyTimes.forEach((event) => {
        // console.log(
        //     'EVENT:',
        //     format(event.start, 'HH:mm'),
        //     format(event.end, 'HH:mm'),
        // );
        filteredWorkingHours = filteredWorkingHours.flatMap((timeSlot) => {
            // console.log(
            //     'WORKING HOURS:',
            //     format(timeSlot.start, 'HH:mm'),
            //     format(timeSlot.end, 'HH:mm'),
            // );
            if (isSameMinute(event.start, timeSlot.start)) {
                // Event starts at the same time as the time slot
                if (isBefore(event.end, timeSlot.end)) {
                    // Event ends before the time slot, so create a new slot from the end of the event to the end of the time slot
                    return [{ start: event.end, end: timeSlot.end }];
                } else {
                    // Event ends at or after the time slot ends, so discard the time slot
                    return [];
                }
            } else if (
                isAfter(event.start, timeSlot.start) &&
                isBefore(event.start, timeSlot.end)
            ) {
                // Event starts after the time slot begins
                if (isAfter(event.end, timeSlot.end)) {
                    // Event ends after the time slot ends, so only keep the part of the slot before the event starts
                    return [{ start: timeSlot.start, end: event.start }];
                } else {
                    // Event ends before the time slot ends, so split the slot into two
                    return [
                        { start: timeSlot.start, end: event.start },
                        { start: event.end, end: timeSlot.end },
                    ];
                }
            } else if (
                isBefore(event.start, timeSlot.start) &&
                isAfter(event.end, timeSlot.start)
            ) {
                // Event starts before the time slot begins but ends after it starts
                return isBefore(event.end, timeSlot.end)
                    ? [{ start: event.end, end: timeSlot.end }]
                    : [];
            } else if (
                isBefore(event.start, timeSlot.start) &&
                isAfter(event.end, timeSlot.end)
            ) {
                // Event completely overlaps the time slot
                return [];
            } else if (
                isBefore(event.start, timeSlot.start) ||
                isAfter(event.end, timeSlot.end)
            ) {
                // Event does not overlap with this time slot
                return [timeSlot];
            }
            return [];
        });
    });

    return filteredWorkingHours;
};

// TODO: Check if it's good
const reduceCalendarGaps = (
    workingHours: TimeSlot[],
    serviceDuration: number,
    noBookBeforeTime: number,
    timeSlotSize: number,
    restTimeBeforeAppointment: number,
    restTimeAfterAppointment: number,
    id: string,
    originalWorkingHours: TimeSlot,
): TimeSlot[] => {
    const now = new Date();
    const noBookingBeforeThreshold = addMinutes(now, noBookBeforeTime);

    // Generate available slots from adjusted working hours
    const availableSlots: TimeSlot[] = [];
    workingHours.forEach(({ start, end }) => {
        const isFirst =
            isSameHour(start, originalWorkingHours.start) &&
            isSameMinute(start, originalWorkingHours.start);
        const isLast =
            isSameHour(end, originalWorkingHours.end) &&
            isSameMinute(end, originalWorkingHours.end);

        let currentStart = start;
        let adjustedCurrentStart = isFirst
            ? start
            : addMinutes(start, restTimeBeforeAppointment);

        while (isBefore(adjustedCurrentStart, end)) {
            // Adjust end with rest time after appointment
            const potentialSlotEnd = addMinutes(
                currentStart,
                serviceDuration + restTimeAfterAppointment,
            );

            // Check if the potential slot end fits within the time slot and after no booking threshold
            const workingHoursEnd = isLast
                ? end
                : subMinutes(end, restTimeAfterAppointment);

            if (
                (isBefore(potentialSlotEnd, workingHoursEnd) ||
                    isEqual(potentialSlotEnd, workingHoursEnd)) && // Adjust end with rest time after appointment
                isAfter(currentStart, noBookingBeforeThreshold)
            ) {
                availableSlots.push({
                    start: currentStart,
                    end: potentialSlotEnd,
                    ownerId: id,
                });
            }

            // Move to the next potential slot start
            currentStart = addMinutes(currentStart, timeSlotSize);
            adjustedCurrentStart = addMinutes(
                currentStart,
                restTimeBeforeAppointment,
            );
        }
    });

    // Sort the slots by start time
    availableSlots.sort((a, b) => a.start.getTime() - b.start.getTime());

    return availableSlots;
};

// TODO: Finish it
const regularStrategy = (
    workingHours: TimeSlot[],
    serviceDuration: number,
    noBookBeforeTime: number,
    timeSlotSize: number,
    restTimeBeforeAppointment: number,
    restTimeAfterAppointment: number,
    id: string,
    originalWorkingHours: TimeSlot,
): TimeSlot[] => {
    const now = new Date();
    const noBookingBeforeThreshold = addMinutes(now, noBookBeforeTime);
    const availableSlots: TimeSlot[] = [];

    workingHours.forEach(({ start, end }) => {
        // const isFirst =
        //     isSameHour(start, originalWorkingHours.start) &&
        //     isSameMinute(start, originalWorkingHours.start);
        const isLast =
            isSameHour(end, originalWorkingHours.end) &&
            isSameMinute(end, originalWorkingHours.end);

        // Align start with the original working hours
        const offsetMinutes = differenceInMinutes(
            start,
            setMinutes(
                setHours(start, originalWorkingHours.start.getHours()),
                originalWorkingHours.start.getMinutes(),
            ),
        );
        let currentStart = subMinutes(start, offsetMinutes % timeSlotSize);

        if (isBefore(currentStart, start)) {
            currentStart = addMinutes(currentStart, timeSlotSize); // Move to the next slot if it starts before the working period
        }

        while (isBefore(currentStart, end)) {
            const adjustedStart = addMinutes(
                currentStart,
                restTimeBeforeAppointment,
            );
            const potentialEnd = addMinutes(
                adjustedStart,
                serviceDuration + restTimeAfterAppointment,
            );

            const workingHoursEnd = isLast
                ? end
                : subMinutes(end, restTimeAfterAppointment);

            // Ensure the potential end is before the end of the working hour and after the current time considering noBookingBeforeThreshold
            if (
                (isBefore(potentialEnd, workingHoursEnd) ||
                    isEqual(potentialEnd, workingHoursEnd)) && // Adjust end with rest time after appointment
                isAfter(currentStart, noBookingBeforeThreshold)
            ) {
                // if (
                //     isAfter(adjustedStart, noBookingBeforeThreshold) &&
                //     isAfter(end, adjustedEnd) &&
                //     isAfter(adjustedEnd, adjustedStart) &&
                //     isAfter(end, potentialEnd)
                // )
                availableSlots.push({
                    start: new Date(currentStart),
                    end: addMinutes(currentStart, timeSlotSize),
                    ownerId: id,
                });
            }

            currentStart = addMinutes(currentStart, timeSlotSize);
        }
    });

    // Sort the slots by start time
    availableSlots.sort((a, b) => a.start.getTime() - b.start.getTime());

    return availableSlots;
};

// TODO: Check with multiple appointments made
const eliminateCalendarGaps = (
    workingHours: TimeSlot[],
    serviceDuration: number,
    noBookBeforeTime: number,
    timeSlotSize: number,
    restTimeBeforeAppointment: number,
    restTimeAfterAppointment: number,
    eliminateGapsSettings: string,
    id: string,
    originalWorkingHours: TimeSlot,
): TimeSlot[] => {
    const now = new Date();
    const noBookingBeforeThreshold = addMinutes(now, noBookBeforeTime);
    const availableSlots: TimeSlot[] = [];

    workingHours.forEach(({ start, end }) => {
        const isFirst =
            isSameHour(start, originalWorkingHours.start) &&
            isSameMinute(start, originalWorkingHours.start);
        const isLast =
            isSameHour(end, originalWorkingHours.end) &&
            isSameMinute(end, originalWorkingHours.end);

        if (
            !(
                eliminateGapsSettings === EliminateGapsStrategy.StartOfDay &&
                isLast
            )
        ) {
            // Adjust for rest times
            const firstTimeSlotStart = start;
            const adjustedFirstTimeSlotStart = isFirst
                ? firstTimeSlotStart
                : addMinutes(start, restTimeBeforeAppointment);
            const firstTimeSlotEnd = addMinutes(
                firstTimeSlotStart,
                restTimeAfterAppointment + serviceDuration,
            );

            // Add the start slot if it's valid
            if (
                isAfter(adjustedFirstTimeSlotStart, noBookingBeforeThreshold) &&
                (isBefore(firstTimeSlotEnd, end) ||
                    isEqual(firstTimeSlotEnd, end))
            ) {
                availableSlots.push({
                    start: firstTimeSlotStart,
                    end: addMinutes(firstTimeSlotStart, serviceDuration),
                });
            }
        }

        if (
            !(
                eliminateGapsSettings === EliminateGapsStrategy.EndOfDay &&
                isLast
            )
        ) {
            // Calculate potential slots around the start and end of working hours
            const lastTimeSlotStart = subMinutes(end, timeSlotSize);
            const potentialLastTimeSlotEnd = isLast
                ? addMinutes(lastTimeSlotStart, serviceDuration)
                : addMinutes(
                      lastTimeSlotStart,
                      serviceDuration + restTimeAfterAppointment,
                  );

            // Add the end slot if it's valid and different from the start slot
            if (
                isAfter(lastTimeSlotStart, noBookingBeforeThreshold) &&
                (isBefore(potentialLastTimeSlotEnd, end) ||
                    isEqual(potentialLastTimeSlotEnd, end))
            ) {
                availableSlots.push({
                    start: lastTimeSlotStart,
                    end: potentialLastTimeSlotEnd,
                    ownerId: id,
                });
            }
        }
    });

    // Sort the slots by start time
    availableSlots.sort((a, b) => a.start.getTime() - b.start.getTime());

    return availableSlots;
};
