import { Business, Location } from '@bookinbio/interface';
import { collection, doc, getDocs } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getLocationsByBusiness = async (
    business?: Business,
): Promise<Location[]> => {
    if (!business) {
        throw new Error('No business was provided');
    }

    const businessDoc = doc(businessesColl, business.id);
    const locationsSnapshot = await getDocs(
        collection(doc(businessesColl, businessDoc.id), 'locations'),
    );

    const locationsData: Location[] = [];
    locationsSnapshot.forEach((doc) => {
        locationsData.push({ id: doc.id, ...doc.data() } as Location);
    });
    return locationsSnapshot.docs.map(
        (locationDoc) =>
            ({ id: locationDoc.id, ...locationDoc.data() } as Location),
    );
};
