import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

import { AppEnvVars, FirebasConfig } from './config';

// Firebase App
const app = initializeApp(FirebasConfig);

// Firebase Auth
export const auth = getAuth(app);

// Firebase Firestore
export const firestore = getFirestore(app);

// Firebase Storagec
export const storage = getStorage(app);

// Firebase Cloud Functions
export const functions = getFunctions(app, 'europe-west3');

// Google Auth Provider
export const googleProvider = new GoogleAuthProvider();

if (AppEnvVars.isDevelopment) {
    connectAuthEmulator(auth, 'http://127.0.0.1:9099');
    connectFirestoreEmulator(firestore, 'localhost', 8080);
    connectFunctionsEmulator(functions, 'localhost', 5001);
}
