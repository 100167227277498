import classNames from 'classnames';

import logoBlack from '../assets/images/logo-black-full.svg';
import logoWhite from '../assets/images/logo-white-full.svg';

import { LanguageMenu } from './LanguageDropdown';

interface HeaderProps {
    theme: 'light' | 'dark' | 'transparent';
}

export const Header = ({ theme }: HeaderProps) => {
    return (
        <div
            className={classNames(
                'fixed top-0 left-0 z-[100] flex w-full items-center justify-between bg-black px-7 pt-4 md:px-48 md:pt-12',
                theme === 'light'
                    ? 'bg-white text-black'
                    : theme === 'dark'
                    ? 'bg-black text-white'
                    : 'bg-transparent text-white',
            )}
        >
            <img
                src={theme === 'light' ? logoBlack : logoWhite}
                alt="bookinbio"
                className="rounded-smd h-8"
            />
            <LanguageMenu theme={theme} />
        </div>
    );
};
