export enum TimeSlotCreationStrategy {
    RegularStrategy = 'RegularStrategy',
    ReduceCalendarGaps = 'ReduceCalendarGaps',
    EliminateCalendarGaps = 'EliminateCalendarGaps',
}

export enum EliminateGapsStrategy {
    StartEndOfDay = 'StartEndOfDay',
    StartOfDay = 'StartOfDay',
    EndOfDay = 'EndOfDay',
}

export enum BusinessType {
    Barbers = 'Barbers',
    BeautySalons = 'BeautySalons',
    HairSalons = 'HairSalons',
    MassageTherapists = 'MassageTherapists',
    PersonalTrainers = 'PersonalTrainers',
    PhysioTherapists = 'PhysioTherapists',
    Photographs = 'Photographs',
    SpaWellness = 'SpaWellness',
    SportsFacilities = 'SportsFacilities',
}

export enum TimeFormat {
    Format12h = 'Format12h',
    Format24h = 'Format24h',
}

export enum EmployeeRole {
    Owner = 'Owner',
    Manager = 'Manager',
    Employee = 'Employee',
}

export enum PriceType {
    Free = 'Free',
    Fixed = 'Fixed',
    From = 'From',
}

export enum AppointmentStatus {
    Pending = 'Pending',
    Cancelled = 'Cancelled',
    Proposed = 'Proposed',
    Declined = 'Declined',
    Approved = 'Approved',
}

export enum RepeatType {
    DoesNotRepeat = 'DoesNotRepeat',
    EveryDay = 'EveryDay',
    EveryWeek = 'EveryWeek',
    EveryMonth = 'EveryMonth',
    Custom = 'Custom',
}

export enum AppointmentActivityActionType {
    Pending = 'Pending',
    Cancelled = 'Cancelled',
    Proposed = 'Proposed',
    Declined = 'Declined',
    Approved = 'Approved',
}

export enum OverrideType {
    WorkingTime = 'WorkingTime',
    OffTime = 'OffTime',
}

export enum EndType {
    SpecificDate = 'SpecificDate',
    After = 'After',
    Infinitely = 'Infinitely',
}

export enum FrequencyType {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
}

export enum UpdateOption {
    OnlySelected = 'OnlySelected',
    SelectedAndFuture = 'SelectedAndFuture',
    All = 'All',
}

export enum GiftCardType {
    TimeExpiration = 'TimeExpiration',
    UsageExpiration = 'UsageExpiration',
    Both = 'Both',
}

export enum DiscountType {
    DiscountSubstract = 'DiscountSubstract',
    DiscountPercentage = 'DiscountPercentage',
    Free = 'Free',
}

export enum ServiceType {
    Service = 'Service',
    Rent = 'Rent',
}

export enum WorkingHourType {
    Default = 'Default',
    SpecificDate = 'SpecificDate',
}
