import { Business, Professional } from '@bookinbio/interface';
import { getDoc } from 'firebase/firestore';

export const getProfessionalsByBusiness = async (
    business?: Business,
): Promise<Professional[]> => {
    if (!business || business?.memberRefs.length === 0) {
        throw new Error('No business or professionals was provided');
    }

    const professionalPromises = await Promise.all(
        business.memberRefs.map(async (member) => {
            const userSnap = await getDoc(member);

            if (!userSnap.exists()) {
                return null;
            }

            return {
                ...userSnap.data(),
                id: userSnap.id,
            } as Professional;
        }),
    );

    const filteredProfessional = professionalPromises.filter(
        (el) => (el && el.allowBookings) || el !== null,
    );

    return filteredProfessional as Professional[];
};
