import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { FailedBooking } from '../components/FailedBooking';
import { LoadingSplash } from '../components/LoadingSplash';
import { BusinessOutlet } from '../context/BusinessContext';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-day-picker/dist/style.css';

const BusinessBooking = lazy(() =>
    import('../pages/BusinessBooking').then((module) => ({
        default: module.BusinessBooking,
    })),
);

const ServicesBooking = lazy(() =>
    import('../pages/ServicesBooking').then((module) => ({
        default: module.ServicesBooking,
    })),
);

// const BookingService = lazy(() =>
//     import('../pages/BookingService').then((module) => ({
//         default: module.BookingService,
//     })),
// );

// const BookingProfessional = lazy(() =>
//     import('../pages/BookingProfessional').then((module) => ({
//         default: module.BookingProfessional,
//     })),
// );

const AppointmentInfo = lazy(() =>
    import('../pages/AppointmentInfo').then((module) => ({
        default: module.AppointmentInfo,
    })),
);

const RedirectLanding = () => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.href = 'https://bookinb.io';
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, []);
    return <LoadingSplash />;
};

export function App() {
    return (
        <>
            <Suspense fallback={<LoadingSplash />}>
                <Routes>
                    <Route element={<BusinessOutlet />}>
                        <Route
                            path=":socialName"
                            element={<BusinessBooking />}
                        />
                        <Route
                            path=":socialName/failed"
                            element={<FailedBooking />}
                        />
                        <Route
                            path=":socialName/services"
                            element={<ServicesBooking />}
                        />
                        <Route path=":socialName/gift-cards" />
                        <Route path=":socialName/memberships" />
                    </Route>
                    <Route
                        path="/:socialName/appointment/:id/*"
                        element={<AppointmentInfo />}
                    />
                    <Route path="*" element={<RedirectLanding />} />
                </Routes>
            </Suspense>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
}
