import { createContext, useContext, useMemo, useState } from 'react';
import { Path, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutlet, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServiceType } from '@bookinbio/enums';
import {
    Business,
    Category,
    Location,
    Professional,
    Rent,
    Service,
} from '@bookinbio/interface';
import { Form } from '@bookinbio/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { addMinutes } from 'date-fns';
import z from 'zod';

import i18n from '../locales/i18n';
import {
    getAvailableTimeSlots2,
    getCategoriesByBusiness,
    getLocationsByBusiness,
    getServicesByBusiness,
    TimeSlot,
} from '../utils/firebase/api';
import { getBusinessBySocialName } from '../utils/firebase/api/get-business';
import { getProfessionalsByBusiness } from '../utils/firebase/api/get-business-professionals';
import { getRentsByBusiness } from '../utils/firebase/api/get-business-rents';
import { bookAppointment } from '../utils/firebase/callable';

export enum BookingSteps {
    IntroText,
    Location,
    Service,
    Provider,
    DateTime,
    OrderSummary,
    PersonalInformation,
}

// const DEFAULT_STEPS =  [
//     BookingSteps.IntroText,
//     BookingSteps.Location,
//     BookingSteps.Service,
//     BookingSteps.Provider,
//     BookingSteps.DateTime,
//     BookingSteps.OrderSummary,
//     BookingSteps.PersonalInformation,
// ];

const DEFAULT_STEPS: Record<
    BookingSteps,
    { show: boolean; next: BookingSteps | null; prev: BookingSteps | null }
> = {
    [BookingSteps.IntroText]: {
        show: true,
        prev: null,
        next: BookingSteps.Location,
    },
    [BookingSteps.Location]: {
        show: true,
        prev: BookingSteps.IntroText,
        next: BookingSteps.Service,
    },
    [BookingSteps.Service]: {
        show: true,
        prev: BookingSteps.Location,
        next: BookingSteps.Provider,
    },
    [BookingSteps.Provider]: {
        show: true,
        prev: BookingSteps.Service,
        next: BookingSteps.DateTime,
    },
    [BookingSteps.DateTime]: {
        show: true,
        prev: BookingSteps.Provider,
        next: BookingSteps.OrderSummary,
    },
    [BookingSteps.OrderSummary]: {
        show: true,
        prev: BookingSteps.DateTime,
        next: BookingSteps.PersonalInformation,
    },
    [BookingSteps.PersonalInformation]: {
        show: true,
        prev: BookingSteps.OrderSummary,
        next: null,
    },
};

interface BusinessContextProps {
    business?: Business;
    isLoadingBusiness: boolean;

    services?: (Service & { professionalIds: string[] })[];
    isLoadingServices: boolean;
    isBooking: boolean;

    categories?: Category[];
    isLoadingCategories: boolean;
    selectedCategoryId: string | null;
    selectCategory: (catgegoryId: string | null) => void;

    professionals?: Professional[];
    isLoadingProfs: boolean;

    rents?: Rent[];
    isLoadingRents: boolean;

    locations?: Location[];
    isLoadingLocations: boolean;

    timeSlots?: TimeSlot[];
    isLoadingTimeSlots: boolean;
    disabledAfter: Date;
    disabledBefore: Date;

    steps: Record<
        BookingSteps,
        { show: boolean; next: BookingSteps | null; prev: BookingSteps | null }
    >;
    currentStep: BookingSteps;
    setStep: (step: number) => void;
    goBack: () => void;
    goForward: () => void;
}

export const BusinessContext = createContext<BusinessContextProps>({
    isLoadingBusiness: false,
    isBooking: false,
    isLoadingServices: false,
    isLoadingCategories: false,
    isLoadingProfs: false,
    isLoadingRents: false,
    isLoadingLocations: false,
    isLoadingTimeSlots: false,
    disabledAfter: new Date(),
    disabledBefore: new Date(),

    selectedCategoryId: null,
    selectCategory: (categoryId: string | null) => {
        console.log('BusinessContext [selectCategory]', categoryId);
    },

    steps: DEFAULT_STEPS,
    currentStep: 0,
    setStep: (step: number) => {
        console.log('BusinessContext [setStep]', step);
    },
    goBack: () => {
        console.log('BusinessContext [goBack]');
    },
    goForward: () => {
        console.log('BusinessContext [goForward]');
    },
});

export const useBusiness = () => {
    const context = useContext(BusinessContext);
    if (context === undefined) {
        throw new Error('useBusiness must be used within a BusinessProvider');
    }
    return context;
};

const BookingSchema = z.object({
    locationId: z.string().min(1),
    serviceIds: z.array(z.string().min(1)),
    professionalId: z.string().optional(),
    randomProfessionalId: z.string().optional(),
    rentIds: z.array(z.string()),
    start: z.date({
        errorMap: (issue: z.ZodIssueOptionalMessage) => {
            if (issue.code === 'invalid_type') {
                return { message: i18n.t('datetime.error.message') };
            }
            return { message: i18n.t('required.error.message') };
        },
    }),
    date: z.date(),
    serviceType: z.string().min(1),

    fullName: z.string().min(1, i18n.t('fullname.error.message')),
    phoneNumber: z.string().min(1, i18n.t('phonenumber.error.message')),
    email: z.string().email({ message: i18n.t('email.error.message') }),
    notes: z.string().optional(),
    isAgreed: z
        .boolean()
        .refine((val) => val, i18n.t('terms.conditions.error.message')),
});

export type BookingForm = z.infer<typeof BookingSchema>;
export type BookingFormProps = Path<BookingForm>;

export const BusinessOutlet = () => {
    const { t } = useTranslation();
    const outlet = useOutlet();
    const { socialName } = useParams<{ socialName: string }>();
    const navigate = useNavigate();

    const [step, setStep] = useState<BookingSteps>(0);
    const [isDirtyStepper, setIsDirtyStepper] = useState<boolean>(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
        null,
    );
    const methods = useForm<BookingForm>({
        mode: 'onSubmit',
        resolver: zodResolver(BookingSchema),
        defaultValues: {
            serviceIds: [],
            rentIds: [],
            isAgreed: false,
            fullName: '',
            email: '',
            phoneNumber: '',
            serviceType: ServiceType.None,
            date: new Date(),
        },
    });
    const [date, serviceIds, rentIds, professionalId, serviceType] =
        methods.watch([
            'date',
            'serviceIds',
            'rentIds',
            'professionalId',
            'serviceType',
        ]);

    const { data: business, isLoading: isLoadingBusiness } = useQuery({
        queryKey: ['business', socialName],
        queryFn: () => getBusinessBySocialName(socialName),
        enabled: !!socialName,
    });

    const { data: services, isLoading: isLoadingServices } = useQuery({
        queryKey: ['business-services', business?.id],
        queryFn: () => getServicesByBusiness(business),
        enabled: !!business,
    });

    const { data: categories, isLoading: isLoadingCategories } = useQuery({
        queryKey: ['business-category', business],
        queryFn: () => getCategoriesByBusiness(business),
        enabled: !!business,
    });

    const { data: locations, isLoading: isLoadingLocations } = useQuery({
        queryKey: ['business-locations', business?.id],
        queryFn: () => getLocationsByBusiness(business),
        enabled: !!business,
    });

    const { data: rents, isLoading: isLoadingRents } = useQuery({
        queryKey: ['business-rents', business?.id],
        queryFn: () => getRentsByBusiness(business),
        enabled: !!business,
    });

    const { data: professionals, isLoading: isLoadingProfs } = useQuery({
        queryKey: ['business-professionals', business?.id],
        queryFn: () => getProfessionalsByBusiness(business),
        enabled: !!business,
    });

    const { data: timeSlots, isLoading: isLoadingTimeSlots } = useQuery({
        queryKey: [
            'time-slots',
            business?.id,
            serviceIds,
            professionalId,
            rentIds,
            date,
        ],
        queryFn: () =>
            getAvailableTimeSlots2({
                selectedDate: date,
                business,
                professionals,
                professionalId,
                services,
                serviceIds,
                serviceType,
                rents,
                rentIds,
            }),
        enabled:
            !!date &&
            !!business &&
            !!professionals &&
            !!services &&
            !!rents &&
            serviceIds.length > 0 &&
            services.length > 0,
    });

    const { mutateAsync: bookAppt, isLoading: isBooking } = useMutation({
        mutationFn: bookAppointment,
    });

    const bookInFutureTime =
        (business?.bookingSettings &&
            business.bookingSettings.bookInFutureTime) ??
        129600;
    const beforeBookTime =
        (business?.bookingSettings &&
            business.bookingSettings.beforeBookTime) ??
        180;
    const disabledAfter = addMinutes(new Date(), bookInFutureTime);
    const disabledBefore = addMinutes(new Date(), beforeBookTime);

    // Handlers
    const handleSubmit = async (data: BookingForm) => {
        if (!business) {
            return;
        }

        try {
            const response = await bookAppt({
                businessId: business.id,
                serviceIds: data.serviceIds,
                professionalId:
                    data.serviceType === ServiceType.Service &&
                    data.professionalId
                        ? data.professionalId
                        : null,
                rentIds:
                    data.serviceType === ServiceType.Rent ? data.rentIds : null,
                serviceType: data.serviceType,
                locationId: data.locationId,

                fullName: data.fullName,
                phoneNumber: data.phoneNumber,
                email: data.email,
                start: data.start.toISOString(),
                notes: data.notes,
            });

            business.bookingSettings.needApprovement
                ? toast.success(t('booking.success.request.toast'))
                : toast.success(t('booking.success.book.toast'));
            navigate(`/${business.socialName}/appointment/${response.data}`);
        } catch (error) {
            console.error(error);
            navigate(`/${business.socialName}/failed`);
        }
    };

    const adjustedSteps = useMemo(() => {
        if (!business || !locations || !professionals) {
            return DEFAULT_STEPS;
        }

        const filtered = DEFAULT_STEPS;
        let step = BookingSteps.IntroText;

        if (
            (business.bookingTexts && !business.bookingTexts.intro) ||
            !business.bookingTexts
        ) {
            filtered[BookingSteps.IntroText].show = false;
            step = BookingSteps.Location;
        }

        if (locations.length <= 1) {
            if (filtered[BookingSteps.IntroText].show) {
                filtered[BookingSteps.Service].prev = BookingSteps.IntroText;
                filtered[BookingSteps.IntroText].next = BookingSteps.Service;
            } else {
                filtered[BookingSteps.Service].prev = null;
                step = BookingSteps.Service;
            }
            filtered[BookingSteps.Location].show = false;
            methods.setValue('locationId', locations[0].id);
        }

        if (
            serviceType === ServiceType.Service &&
            (professionals.length <= 1 ||
                !business.bookingSettings.professionalSelectionEnabled)
        ) {
            if (professionals.length === 1) {
                methods.setValue('professionalId', professionals[0].id);
            }

            filtered[BookingSteps.Service].next = BookingSteps.DateTime;
            filtered[BookingSteps.DateTime].prev = BookingSteps.Service;
            filtered[BookingSteps.Provider].show = false;
        } else {
            filtered[BookingSteps.Service].next = BookingSteps.Provider;
            filtered[BookingSteps.DateTime].prev = BookingSteps.Provider;
            filtered[BookingSteps.Provider].show = true;
        }

        if (!isDirtyStepper) {
            setStep(step);
        }

        return filtered;
    }, [
        business,
        isDirtyStepper,
        locations,
        methods,
        professionals,
        serviceType,
    ]);

    const handleBack = () => {
        if (selectedCategoryId) {
            setSelectedCategoryId(null);
            return;
        }

        const backStep = adjustedSteps[step].prev;

        if (backStep === null) {
            navigate(`/${business?.socialName}`);
            methods.reset();
        } else {
            setStep(backStep);
        }
    };

    const handleForward = () => {
        const nextStep = adjustedSteps[step].next;
        setIsDirtyStepper(true);

        switch (nextStep) {
            case BookingSteps.Service: {
                if (!methods.getValues('locationId')) {
                    methods.setError('locationId', {
                        message: t('location.error.message'),
                    });
                    return;
                } else {
                    methods.clearErrors([
                        'locationId',
                        'serviceIds',
                        'professionalId',
                        'rentIds',
                        'start',
                    ]);
                }
                setStep(BookingSteps.Service);
                break;
            }
            case BookingSteps.Provider: {
                if (methods.getValues('serviceIds').length === 0) {
                    methods.setError('serviceIds', {
                        message: t('service.error.message'),
                    });
                    return;
                } else {
                    methods.clearErrors([
                        'locationId',
                        'serviceIds',
                        'professionalId',
                        'rentIds',
                        'start',
                    ]);
                }
                setStep(BookingSteps.Provider);
                break;
            }
            case BookingSteps.DateTime: {
                if (
                    serviceType === ServiceType.Service &&
                    !methods.getValues('professionalId')
                ) {
                    methods.setError('professionalId', {
                        message: t('professional.error.message'),
                    });
                    return;
                } else if (
                    serviceType === ServiceType.Rent &&
                    methods.getValues('rentIds').length === 0
                ) {
                    methods.setError('rentIds', {
                        message: t('rent.error.message'),
                    });
                    return;
                } else {
                    methods.clearErrors([
                        'locationId',
                        'serviceIds',
                        'professionalId',
                        'rentIds',
                        'start',
                    ]);
                }
                setStep(BookingSteps.DateTime);
                break;
            }
            case BookingSteps.OrderSummary: {
                if (!methods.getValues('date') || !methods.getValues('start')) {
                    methods.setError('start', {
                        message: t('datetime.error.message'),
                    });
                    return;
                } else {
                    methods.clearErrors([
                        'locationId',
                        'serviceIds',
                        'professionalId',
                        'rentIds',
                        'start',
                    ]);
                }
                setStep(BookingSteps.OrderSummary);
                break;
            }
            case BookingSteps.PersonalInformation: {
                methods.clearErrors([
                    'fullName',
                    'isAgreed',
                    'phoneNumber',
                    'email',
                ]);
                setStep(BookingSteps.PersonalInformation);
                break;
            }
            default: {
                if (nextStep) setStep(nextStep);
                break;
            }
        }
    };

    return (
        <BusinessContext.Provider
            value={{
                business,
                isLoadingBusiness,
                isBooking,

                services,
                isLoadingServices,

                categories,
                isLoadingCategories,
                selectedCategoryId,
                selectCategory: (categoryId: string | null) =>
                    setSelectedCategoryId(categoryId),

                professionals,
                isLoadingProfs,

                rents,
                isLoadingRents,

                locations,
                isLoadingLocations,

                timeSlots,
                isLoadingTimeSlots,
                disabledAfter,
                disabledBefore,

                steps: adjustedSteps,
                currentStep: step,
                setStep: (step) => setStep(step),
                goBack: handleBack,
                goForward: handleForward,
            }}
        >
            <Form methods={methods} onSubmit={handleSubmit}>
                {outlet}
            </Form>
        </BusinessContext.Provider>
    );
};
