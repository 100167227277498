import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon, IconType } from '@bookinbio/ui';

import { Header } from './Header';

export const FailedBooking = () => {
    const { t } = useTranslation();
    const { socialName } = useParams<{ socialName: string }>();
    const navigate = useNavigate();

    const handleTryAgain = () => {
        navigate(`/${socialName}/services`);
    };

    return (
        <div className="h-custom-screen flex flex-col overflow-hidden px-7 sm:px-48 sm:pb-24">
            <Header theme="light" />
            <div className="flex flex-1 flex-col items-center justify-center gap-y-1">
                <h1 className="font-excon text-lg font-medium">
                    {t('booking.failed')}
                </h1>
                <span className="text-sm text-gray-500">
                    {t('booking.tryagain')}
                </span>
            </div>
            <div className="fixed bottom-0 left-0 grid w-full gap-3 bg-white px-7 pt-2 pb-4 sm:px-48 sm:pb-12">
                <button
                    type="button"
                    className="flex h-10 items-center justify-center gap-x-2 rounded-lg border border-black bg-black text-sm text-white hover:bg-gray-900"
                    onClick={handleTryAgain}
                >
                    <Icon name={IconType.TryAgain} />
                    <span>{t('tryagain')}</span>
                </button>
                <span className="text-center text-xs text-gray-500">
                    {t('support.contact')}{' '}
                    <a
                        className="text-black hover:text-gray-800"
                        href="mailto:support@bookinb.io"
                    >
                        support@bookinb.io.
                    </a>
                </span>
            </div>
        </div>
    );
};
