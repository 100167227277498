export const AppEnvVars = {
    FirebaseApiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    FirebaseAuthDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    FirebaseProjectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    FirebaseStorageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    FirebaseMessageSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
    FirebaseAppId: import.meta.env.VITE_FIREBASE_APP_ID,
    FirebaseMeasurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
    MapKey: import.meta.env.VITE_MAP_KEY,
    isDevelopment: import.meta.env.VITE_DEVELOPMENT === 'true' ? true : false,
    SentryDsn: import.meta.env.VITE_SENTRY_DSN,
    SentryAuthToken: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
    SentryEnabled: import.meta.env.VITE_SENTRY_ENABLED,

    ClientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    ClientSecret: import.meta.env.VITE_GOOGLE_CLIENT_SECRET,

    AppVersion: import.meta.env.COMMIT_HASH,
};

export const AppConfig = {
    appVersion: AppEnvVars.AppVersion,
};

export const FirebasConfig = {
    apiKey: AppEnvVars.FirebaseApiKey,
    authDomain: AppEnvVars.FirebaseAuthDomain,
    projectId: AppEnvVars.FirebaseProjectId,
    storageBucket: AppEnvVars.FirebaseStorageBucket,
    messagingSenderId: AppEnvVars.FirebaseMessageSenderId,
    appId: AppEnvVars.FirebaseAppId,
    measurementId: AppEnvVars.FirebaseMeasurementId,
};

export const SentryConfig = {
    dsn: AppEnvVars.SentryDsn,
    authToken: AppEnvVars.SentryAuthToken,
    enabled: AppEnvVars.SentryEnabled,
};

export const GoogleConfig = {
    clientSecret: AppEnvVars.ClientSecret,
    clientId: AppEnvVars.ClientId,
};
