import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconType } from '@bookinbio/ui';
import {
    autoUpdate,
    FloatingFocusManager,
    useDismiss,
    useFloating,
    useFocus,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';

interface LanguageMenuProps {
    theme: 'light' | 'dark' | 'transparent';
}

export const LanguageMenu = ({ theme }: LanguageMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();

    const LANGUAGES = [
        { label: t('cs'), value: 'cs' },
        { label: t('en'), value: 'en' },
        { label: t('hu'), value: 'hu' },
        { label: t('pl'), value: 'pl' },
        // { label: t('ru'), value: 'ru' },
        { label: t('sk'), value: 'sk' },
        { label: t('uk'), value: 'uk' },
    ];

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        placement: 'bottom-end',
    });

    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'menu' });

    // Merge all the interactions into prop getters
    const { getReferenceProps, getFloatingProps } = useInteractions([
        focus,
        dismiss,
        role,
    ]);

    const handleLanguageChange = async (value: string) => {
        localStorage.setItem('I18N_LANGUAGE', value);
        await i18n.changeLanguage(value);
        setIsOpen(false);
    };

    const currentLanguageOption = LANGUAGES.find(
        (lang) => lang.value === i18n.language,
    );

    return (
        <>
            <button
                type="button"
                ref={refs.setReference}
                {...getReferenceProps()}
                onClick={() => setIsOpen((p) => !p)}
                className={classNames(
                    'group flex items-center gap-x-1 rounded-md px-2 py-2 text-sm',
                    theme === 'light'
                        ? 'text-black hover:text-gray-500 focus:text-gray-500'
                        : 'text-white hover:text-gray-500 focus:text-gray-500',
                )}
            >
                {currentLanguageOption?.label}
                <Icon
                    name={IconType.ChevronRight}
                    size={16}
                    className={isOpen ? '-rotate-90' : 'rotate-90'}
                />
            </button>
            <FloatingFocusManager context={context} modal={false}>
                <Transition
                    as={Fragment}
                    show={isOpen}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <div
                        ref={refs.setFloating}
                        {...getFloatingProps()}
                        className="grid w-32 divide-y divide-gray-100 rounded-md bg-white p-1 text-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        style={floatingStyles}
                    >
                        {LANGUAGES.map(({ label, value }) => {
                            return (
                                <LanguageItem
                                    key={`language-item-${value}`}
                                    label={label}
                                    onClick={() => handleLanguageChange(value)}
                                />
                            );
                        })}
                    </div>
                </Transition>
            </FloatingFocusManager>
        </>
    );
};

interface LanguageItemProps {
    label: string;
    onClick: () => void;
}

export const LanguageItem = ({ label, onClick }: LanguageItemProps) => {
    return (
        <button
            type="button"
            className={classNames(
                'group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-black hover:text-white',
            )}
            onClick={onClick}
        >
            {label}
        </button>
    );
};
